(function(){window.i18nData={
    "home": {
        "sloganManyCars": "Miles de personas con el mismo auto... y&nbsp;los&nbsp;mismos&nbsp;problemas",
        "sloganShare": "Comparte. &nbsp; Ayuda. &nbsp; Recibe ayuda.",
        "explanationInfographicAlt": "Una comunidad para ayudarnos mutuamente",
        "howToStart": "¿Cómo empezar?",
        "pickBrand": "Elige la marca de tu vehículo:",
        "alreadyUser": "¿Eres miembro de %s?",
        "ifNew": "Si eres nuevo,",
        "canRegisterHere": "puedes registrarte aquí",
        "doubts": "¿Dudas? ¿Preguntas? ¿Comentarios?",
        "accountInfo": "Información sobre tu cuenta, tus autos y tu actividad en el sitio",
        "howDoesItWork": "¿Cómo funciona?",
        "moreBrands": "VER MÁS MARCAS",
        "youCanAlsoNavigateByModel": "También puedes buscar por marca y modelo",
        "linkLogin": "INICIAR SESIÓN",
        "linkPrivacyPolicy": "Privacidad",
        "linkTermsOfUse": "Condiciones",
        "linkContactUs": "Feedback",
        "linkPublishWithUs": "Anuncia en el sitio",
        "searchPlaceholder": "Escribe tu problema o pregunta...",
        "forExampleShort": "Ej:&nbsp;",
        "step1Share": "Cuenta tu problema",
        "step1Explanation": "Describe detalladamente tu problema, para que otros miembros de la comunidad puedan ayudarte. Opinautos tiene más de <strong>600.000</strong> casos reportados.",
        "step2GetHelp": "Recibe ayuda",
        "step2Explanation": "Además de ayudarte, nuestro objetivo es recopilar toda la información sobre los problemas existentes y sus posibles causas, gracias a la ayuda de nuestra comunidad de más de <strong>1.000.000</strong> miembros.",
        "step3ShareSolution": "Cuenta cómo lo has resuelto",
        "step3Explanation": "Si has resuelto tu problema, comparte la solución: las próximas personas que lo tengan podrán aprender de tu experiencia.",
        "step4BePart": "Participa de la comunidad",
        "step4Explanation": "Responde, vota, comparte tu conocimiento: ¡La comunidad de Opinautos está compuesta de entusiastas como tú!",
        "visitSiteInCountry": "Navegar en el sitio de:"
    },
    "misc": {
        "anonymous": "Anónimo",
        "or": "o"
    },
    "emailAddresses": {
        "help": "ayuda@opinautos.com",
        "info": "info@opinautos.com",
        "problems": "problemas@opinautos.com",
        "feedback": "feedback@opinautos.com",
        "professionals": "profesionales@opinautos.com"
    },
    "facebook": {
        "solution": {
            "template": "Indicaron una solución al problema de {{make}} {{model}} '{{shortTitle}}...', ¡Clickea acá para leerla!"
        },
        "firstAnswer": {
            "template": "{{actionAuthor}} respondió a tu problema '{{shortTitle}}...', ¡Clickea acá para leerlo!"
        },
        "newAnswer": {
            "template": "Nueva respuesta de {{actionAuthor}} en {{make}} {{model}} '{{shortTitle}}...', ¡Clickea acá para leerla!"
        }
    },
    "emails": {
        "welcome": {
            "helloAndWelcomeUser": "Hola <strong>%s</strong>, bienvenido a Opinautos!",
            "thanksForJoining": "Gracias por sumarte :)",
            "clickToActivateUser": "Para activar tu usuario '<em>%s</em>' debes hacer click en este link:&nbsp;",
            "confirmRegistrationButton": "Confirma tu registración haciendo CLICK aquí",
            "weWillEmailYou": "Si te responden o te piden ayuda, te estaremos <strong>enviando un email</strong>!",
            "contactUs": "Cualquier duda escríbenos a <a href='mailto:%s'>%s</a>!",
            "weWillEmailYouOnFuseboxUpdates": "Ya estamos trabajando en el pedido del diagrama de fusibles para tu {{marca}} {{modelo}}! Te avisaremos cuando tengamos novedades.<br><br>Mientras tanto te invitamos a leer <a href={{url}} >lo que se está hablando en la comunidad sobre los fusibles de tu auto</a>.<br><br>Estás en el lugar perfecto para encontrar lo que buscas."
        },
        "solution": {
            "subject": "Se resolvió '%s...' en Opinautos!",
            "markedAsSolved": "¡El reporte del %s %s fue solucionado!",
            "readButtonContent": "¡Lee la solución y entérate cómo!"
        },
        "newAnswer": {
            "subject": "Hubo una nueva respuesta a '%s...' en Opinautos!",
            "thereIsANewComment": "Hay un nuevo comentario en el reporte del %s %s!",
            "theUserWrote": "El usuario <strong>%s</strong> respondió y escribió:",
            "theUserWroteNoContent": "El usuario <strong>%s</strong> respondió.",
            "readAndReplyButton": "Lee la respuesta completa y responde haciendo CLICK aquí",
            "readAndReplyButtonNoContent": "Lee la respuesta y responde haciendo CLICK aquí"
        },
        "firstAnswer": {
            "subject": "Te respondieron '%s...' en Opinautos!",
            "thereHasBeenAReply": "Respondieron a lo que has escrito sobre el %s %s!",
            "theUserAnsweredYou": "El usuario <strong>%s</strong> te respondió y escribió:",
            "theUserAnsweredYouNoContent": "El usuario <strong>%s</strong> te respondió.",
            "ifSolvedShare": "Si resolviste tu problema, no te olvides de COMPARTIR CÓMO!"
        },
        "footer": {
            "didYouForgotPassword": "¿Olvidaste tu contraseña de Opinautos? ",
            "getInstructionsToReset": "Recibe instrucciones sobre cómo restablecerla.",
            "youCanAlso": "Puedes ",
            "cancelEmailSuscription": "desuscribirte a estos correos",
            "orChangeYour": " o cambiar tu",
            "notificationsConfiguration": "configuración de notificaciones.",
            "doYouNeedHelp": " ¿Necesitas ayuda?",
            "ifYouDidntRegister": "Si has recibido este mensaje por error y nunca has creado una cuenta en Opinautos, haz click ",
            "notMyAccount": "aquí"
        },
        "passwordReset": {
            "subject": "¿Olvidaste tu contraseña en Opinautos?",
            "someoneAskForReset": "Han reportado que olvidaron la contraseña para esta cuenta en Opinautos.com",
            "ifItHasBeenYou": "Si has sido tú, elige una nueva contraseña haciendo click en el siguiente link:&nbsp;",
            "pickNewPass": "Elegir una nueva contraseña",
            "ifNotYouIgnoreEmail": "Si no has sido tú, ignora este email."
        },
        "emailConfirmation": {
            "subject": "%s, confirma tu email en Opinautos!"
        },
        "common": {
            "opinautosTeamSignature": "El equipo de Opinautos",
            "javierSignature": "Javier - Equipo de Opinautos",
            "helloUser": "Hola <strong>%s</strong>,",
            "usernameOnlyGreeting": "%s,"
        },
        "campaigns": {
            "subject": "Hola {{username}}, ¿tienes novedades de tu {{marca}} {{modelo}}?",
            "repliedByAuthorButUnsolved": {
                "yourProblemIsNotMarkedSolved": "Notamos que tu reporte <em>'{{titulo}}'</em> de tu {{marca}} {{modelo}} aún no ha sido marcado como solucionado.",
                "ifSolvedMarkIt": "<strong>Si ya has resuelto el problema</strong>, ¡cuenta a la comunidad cómo lo hiciste! Agrega un comentario en tu reporte y clickea <em>'Aceptar como solución al problema'</em> sobre tu comentario.",
                "ifNotSolvedShareAnyNews": "<strong>Si aún no lo has resuelto pero tienes alguna novedad, ¡compártela!</strong> Escribe un comentario con esa información para que puedan seguir ayudándote.",
                "clickHereToShareNews": "Mira y comenta tu reporte haciendo CLICK aquí",
                "variations": {
                    "bodies": {
                        "youveInteracted": "Luego de tu intercambio de mensajes con la comunidad de Opinautos, ¿pudiste resolver el problema con tu {{modelo}}?",
                        "shareSolutionAndGainPoints": "Si conoces la solución a tu problema, ¡coméntala y sube en el ranking de Opinautos!"
                    },
                    "subjects": {
                        "nOwnersAwaitYourSolution": "{{interestedOwnersCount}} dueños esperan la solución de tu {{modelo}}",
                        "haveYouFoundTheSolutionGainPoints": "¿Pudiste solucionar tu problema? ¡Gana puntos!"
                    }
                }
            },
            "noReplies": {
                "noRepliesYet": "Sabemos que el reporte <em>'{{titulo}}'</em> de tu {{marca}} {{modelo}} no ha tenido respuestas todavía, pero <strong>¡no te desanimes!</strong>",
                "shareAnyNews": "Si tienes alguna novedad sobre el problema o más detalles, cuéntale a los demás miembros para que puedan ayudarte y aprender de tu experiencia.",
                "variations": {
                    "subjects": {
                        "nPeopleReadYou": "{{interestedUsersCount}} personas ya leyeron la consulta de tu {{modelo}}",
                        "haveYouFixed": "¿Ya arreglaste tu {{modelo}}, {{username}}?",
                        "tellMeIfFixed": "{{username}}, cuéntame si solucionaste lo de tu {{modelo}}",
                        "anotherUserAsksIfSolved": "{{interestedUser}} pregunta si resolviste lo de tu {{modelo}}",
                        "haveYouSolved": "¿Ya resolviste el problema con tu {{modelo}}?",
                        "haveYouSolvedOthersAreInterested": "¿Ya resolviste el problema con tu {{modelo}}? {{interestedOwnersCount}} dueños quieren saber",
                        "nOwnersAskAboutYourProblem": "{{interestedOwnersCount}} dueños de {{modelo}} nos preguntan por tu problema",
                        "getMoreHelp": "¡Recibe más ayuda con tu {{modelo}}!"
                    },
                    "bodies": {
                        "showingThousandsOfPeople": "Aunque todavía no conseguimos una respuesta, seguimos mostrando tu consulta a miles de personas.",
                        "weAreLookingForTheAnswer": "Seguimos en la búsqueda de respuestas para tu consulta.",
                        "otherUserAsks": "{{interestedUser}} y otros usuarios nos están preguntando por tu problema con el {{model}}.<br/><br/>Ayúdalos contando si ya lo resolviste y cómo:",
                        "weWillSoonFindAnAnswer": "Seguimos buscando al mejor dueño de {{modelo}} para responder a tu consulta.",
                        "meanwhileTellUsIfSolved": "Mientras tanto, cuéntanos si has resuelto el problema.",
                        "theMoreWeKnowKeepUsUp": "Cuanto más sepamos, más rápido encontraremos la solución. Si aún no has resuelto el problema, cuéntanos si tienes novedades.",
                        "meanWhileKeepUsUpIfNews": "Mientras tanto, ¿tienes novedades de tu {{modelo}}? Cuanto más sepamos, más rápido encontraremos la solución.",
                        "meanwhileHaveYouProgressed": "Mientras tanto, ¿has hecho algún progreso?",
                        "haveYouSolvedTheProblem": "¿Ya has resuelto el problema con tu {{modelo}}?"
                    }
                }
            },
            "authorDidntComeBack": {
                "peopleRepliedWhereAreYou": "Hubo respuestas al reporte <em>'{{titulo}}'</em> de tu {{marca}} {{modelo}}, pero <strong>no supimos más nada de ti</strong>! ¿Fueron útiles? Si alguna de ellas te sirvió como solución, ¡clickea en <em>'Aceptar como solución al problema'</em> sobre esa respuesta!",
                "variations": {
                    "subjects": {
                        "nOwnersWroteToYouAbout": "{{interestedOwnersCount}} dueños te escribieron por tu {{modelo}}. ¡Respóndeles!",
                        "whatEverHappendTellUsMore": "¿Qué pasó con tu {{modelo}}? Cuéntanos más",
                        "nOwnersAskUsAboutYourProblem": "{{interestedOwnersCount}} dueños de {{modelo}} nos preguntan por tu problema",
                        "nOwnersAwaitYourComments": "{{interestedOwnersCount}} dueños siguen esperando que respondas"
                    },
                    "bodies": {
                        "commentsAwaitHaveYouSolved": "¡Hay comentarios esperándote! ¿Ya descubriste la solución al problema con tu {{modelo}}?",
                        "haveYouSolved": "¿Ya descubriste la solución al problema con tu {{modelo}}?"
                    }
                }
            },
            "contributor": {
                "variations": {
                    "subjects": {
                        "help": "{{username}}, ¡mira a cuántos ayudaste en Opinautos!",
                        "points": "{{username}}, ¡tus aportes en Opinautos siguen sumando puntos!",
                        "ranking": "{{username}}, ¡sigues subiendo en el ranking de Opinautos!",
                        "contribute": "{{username}}, ¡los usuarios de Opinautos te necesitan!",
                        "communityWantsYourOpinions": "¡La comunidad quiere más de tus opiniones!",
                        "moreOwnersWantToHearFromYou": "¡Más dueños de {{modelo}} quieren tu opinión! 🚗",
                        "keepEarningPoints": "¡Sigue sumando puntos en Opinautos! 💯"
                    },
                    "bodies": {
                        "questions": "¡Tus respuestas ayudaron a muchas personas! Ayuda a más dueños respondiendo alguna de estas preguntas:",
                        "help": "Tus respuestas ayudaron a muchas personas, y todavía hay muchas otras que te necesitan.",
                        "points": "Tus respuestas ayudaron a muchas personas, vuelve y sigue sumando puntos.",
                        "ranking": "Tus respuestas ayudaron a muchas personas, vuelve a responder y sigue subiendo posiciones en el ranking.",
                        "contribute": "Tus respuestas ayudaron a muchas personas, y todavía hay muchas otras que te necesitan."
                    },
                    "buttons": {
                        "questions": "Ver todas las preguntas",
                        "help": "¡Haz click aquí para ver a cuántos has ayudado!",
                        "points": "¡Haz click aquí para ver tu puntaje!",
                        "ranking": "¡Haz click aquí para ver tu ranking!",
                        "contribute": "¡Haz click aquí para seguir colaborando!"
                    }
                }
            },
            "contribution": {
                "manualApproved": {
                    "body": "Ya verificamos y publicamos el manual que subiste. <strong>¡Gracias! Es muy útil para la comunidad 💙</strong>",
                    "subject": "Publicamos tu manual del {{modelo}} 🚀",
                    "btnSeeManual": "Mira el manual publicado"
                },
                "fuseboxImageApproved": {
                    "body": "Ya verificamos la foto de fusiblera que subiste. <strong>¡Gracias! Es muy útil para la comunidad 💙</strong>",
                    "subject": "Procesamos tu foto de fusiblera del {{modelo}} 🚀"
                },
                "tirePressureLabelImageApproved": {
                    "body": "Ya verificamos la foto de la etiqueta de presión de los neumáticos que subiste. <strong>¡Gracias! Es muy útil para la comunidad 💙</strong>",
                    "subject": "Procesamos tu foto de la etiqueta de presión de los neumáticos del {{modelo}} 🚀"
                },
                "common": {
                    "oneMoreContrib": "Esta contribución da puntos y te hace subir en el ranking 🚀"
                }
            },
            "dataFeedback": {
                "fusebox": {
                    "requestInfo": {
                        "subject": "Diagrama de fusibles {{model.modelo}} {{year}}",
                        "body": "Estuvimos buscando tu fusiblera ({{modelo}} {{year}}), pero no conseguimos el manual de usuario que normalmente contiene el diagrama de fusibles. <br/><br/><strong>Si tienes el manual de tu {{modelo}}</strong>, busca \"fusibles\" en el índice. Si lo encuentras y <strong>nos respondes con las fotos</strong> del manual, podemos crear el diagrama interactivo y enviártelo (y de paso ayudas a otros dueños que lo necesitan 😉).<br/> <br/><strong>Si no tienes el manual</strong>, algunas veces la tapa de plástico de la fusiblera contiene un diagrama, si nos lo envias lo revisamos. ",
                        "suggestedFusebox": "💡 Vimos que la foto que nos enviaste es muy similar a <a href='{{url}}' target='_blank'>esta fusiblera del año {{suggestedYear}}</a>, quizás te sirva. ¡Cuéntanos si ayudó!"
                    },
                    "requestInfoSuggest": {
                        "subject": "Diagrama de fusibles {{model.modelo}} {{year}}, quizás te sirva"
                    },
                    "weFoundFusebox": {
                        "subject": "Te conseguimos el diagrama de fusibles de tu {{model.marca}} {{model.modelo}} {{year}}!",
                        "body": "Nos llevó un rato, pero <strong>conseguimos el diagrama de fusibles</strong> para tu {{modelo}} {{year}} 😁<br/><br/>¡Ya lo publicamos, <a href='{{fuseboxUrl}}' target='_blank'>lo puedes ver en este link</a>!<br/> <br/>Por cualquier duda o error escríbenos. Fue clave la ayuda de otros dueños, te invitamos a seguir sumando en nuestra comunidad del {{modelo}} 🛠"
                    },
                    "alreadyHaveFusebox": {
                        "body": "Recibimos la foto de tu fusiblera, perdón por la demora!</br><br/> Ya tenemos publicado este <a href='{{url}}' target='_blank'>diagrama de fusibles para {{modelo}} {{year}}</a> que coincide con tu foto. <br/> ¿Hay alguna consulta que nos quieras hacer? Cuéntanos si te resultó útil!",
                        "sorryComment": "Esta vez hemos tardado un poco, pero ya pudimos procesarlo! Le ponemos mucha atención de forma individual a cada caso.</br><br/>"
                    }
                },
                "customSubject": "{{subjectHtml}}"
            },
            "scoreChange": {
                "subject": "¡Felicitaciones {{username}}, estás subiendo en el ranking de Opinautos!",
                "content": "Tus respuestas ayudaron a muchas personas, y todavía hay muchas otras que te necesitan.",
                "link": "¡Haz click aquí para seguir colaborando!",
                "yourCurrentRanking": "Tu ranking actual",
                "seeProfileAndStats": "Ver todo tu perfil y estadísticas...",
                "variations": {
                    "bodies": {
                        "yourCommentHelpedMoreNeeded": "Tu comentario ayudó a muchos dueños de {{modelo}}, ¡Gracias&nbsp;🙌!<br/><br/>Todavía hay más que te necesitan&nbsp;🙏",
                        "youWereVotedKeepContributing": "Tu comentario del {{modelo}} fue votado como útil :)<br/>¡Sigue respondiendo! Ayuda y suma puntos"
                    },
                    "buttons": {
                        "checkYourVotedComment": "Ver comentario votado",
                        "helpAnsweringMore": "Ayuda en otras preguntas"
                    },
                    "subjects": {
                        "youGainedNVotesCheckOutRankingSingle": "¡Sumaste 1 voto! Mira tu nuevo ranking ahora",
                        "youGainedNVotesCheckOutRankingMultiple": "¡Sumaste {{newVoteCount}} votos! Mira tu nuevo ranking ahora",
                        "youGainedNAcceptedSolutions": "¡{{solvedCommentCount}} de tus comentarios fueron marcados como solución!",
                        "youGainedNVotesSingle": "Sumaste 1 voto en este comentario",
                        "youGainedNVotesMultiple": "Sumaste {{newVoteCount}} votos en estos comentarios",
                        "checkOutYourGainedVotesSingle": "¡Mira el voto que recibieron tus comentarios!",
                        "checkOutYourGainedVotesMultiple": "¡Mira los {{newVoteCount}} votos que recibieron tus comentarios!",
                        "youClimbedNPositionsInRanking": "¡Subiste {{rankingClimb}} posiciones en el ranking! Mira por qué 🔧",
                        "youClimbedToPositionInRanking": "🎉 ¡Subiste al puesto {{currentRanking}} en el ranking de Opinautos! 🎉",
                        "congratsYouAreClimbing": "¡Felicitaciones {{username}}, estás subiendo en el ranking de Opinautos!",
                        "yourCommentWasVoted": "Votaron positivo tu comentario del {{modelo}}",
                        "yourCommentGotVoted": "Te votaron el comentario del {{modelo}}!",
                        "yourWereVotedCheckItOut": "¡Te votaron! Mira el comentario"
                    }
                }
            },
            "common": {
                "buttons": {
                    "yesISolvedTheProblem": "Sí, resolví el problema",
                    "iSolvedTheProblem": "Ya resolví el problema",
                    "iHaventSolvedItYet": "Todavía no lo resolví",
                    "noIHaventSolvedItYet": "No, todavía no lo resolví",
                    "clickIfHaveNews": "Comparte tus novedades",
                    "updateTheCommunity": "Cuéntale a la comunidad de tu progreso"
                }
            }
        },
        "spammerEngagement": {
            "ifMechanicThenRegisterAsSuch": "Si ofreces servicios profesionales y deseas mostrar tu información de contacto, <span style=\"color:MediumSeaGreen;\">puedes anotarte en nuestra lista de profesionales</span> y mostraremos tus datos junto a tus comentarios útiles.",
            "registerAsMechanicButton": "Clickea aquí para anotarte como profesional",
            "weHaveDetectedSpam": "Detectamos que has escrito comentarios que violan nuestras políticas de uso del sitio. Te advertimos que serán eliminados todos aquellos comentarios que: <ul style=\"color:Tomato;\"> <li>No aporten información útil a los usuarios.</li> <li>Contengan sólo publicidad (por ejemplo, <i>\"Yo te ayudo, escribime al 1122334455\"</i>).</li> </ul> ",
            "youllBeBlocked": "El envío reiterado de estos comentarios llevará al bloqueo de tu usuario.",
            "subject": "ADVERTENCIA: Uso inapropiado de Opinautos"
        }
    },
    "messages": {
        "more": {
            "whileYouGetAReplyHelp": "Mientras te responden, sigue ayudando:",
            "doReplies": "Responde y comenta cuando puedas aportar algo",
            "doVotes": "Vota los comentarios útiles",
            "doReports": "Reporta burlas y spam",
            "doReviewLeave": "Deja una opinión",
            "doReviewOfYourVehicle": " de tu vehículo",
            "doAndGetPoints": "¡Suma puntos y sube en el <a href='{{url}}'>ranking de miembros</a>!",
            "dontForgetTo": "No olvides:"
        },
        "addReport": {
            "yourReportIsPublished": "¡Ya está publicada tu consulta en la comunidad!",
            "youllGetEmailOnReply": "Cuando te respondan te avisaremos por email",
            "anAnswerMayTakeSomeTime": "Puede que la comunidad demore unas horas o días en responder",
            "clickTheLinkSentTo": "Clickea el link que te enviamos a",
            "andActivateyourAccount": " para activar tu perfil en Opinautos"
        },
        "addReview": {
            "yourReviewIsPublished": "¡Gracias! Tu opinión ya está publicada"
        },
        "welcomeAfterAddProblem": {
            "welcome": "¡Bienvenido/a a Opinautos!",
            "whileYouGetHelp": "Somos una comunidad de dueños que se ayudan unos a otros:"
        },
        "welcomeAfterReply": {
            "thankYou": "¡Gracias <strong>{{user}}</strong>!<br> Y bienvenido/a a Opinautos",
            "weSentYouAnEmail": "Para terminar de activar tu cuenta clickea el link que te enviamos a <strong>{{email}}</strong>"
        },
        "confirmationResent": {
            "weChangedYourEmail": "Cambiamos tu email a <strong>'%s'</strong>. Confirma tu usuario haciendo click en el correo que te enviamos.",
            "ifProblemsEmailUs": "Si sigue sin llegar escríbenos a <strong><a href='mailto:%s'>%s</a></strong>"
        },
        "keepHelping": "Sigue ayudando:",
        "thankYouUser": "¡Gracias {{user}}!",
        "feedbackSent": "<p>¡Gracias, tus comentarios nos ayudan a mejorar!</p><p>Sigue colaborando con la comunidad votando los mensajes que encuentres útiles.</p>",
        "surveyFollowupSent": "<p>¡Muchísimas gracias por compartir tus comentarios, nos ayudan a mejorar el sitio!</p>",
        "failedFacebookLogin": "<p>Opinautos no recibió autorización por parte de Facebook.</p><p>Si no deseas usar facebook, puedes registrarte usando cualquier email.",
        "failedFacebookLoginTitle": "Falló la autenticación con Facebook",
        "passwordReseted": "<p>La contraseña se cambió exitosamente!</p><p>A continuación ingresa con tu nueva contraseña</p>",
        "emailAlreadyExists": "<p>Eso significa que <strong>en el pasado ya creaste un usuario</strong> en Opinautos usando el correo <em>%s</em></p><p>Escribe tu contraseña o ingresa con facebook:</p>",
        "defectoAgregado": "<p>Tu reporte fue agregado exitosamente.</p><p>Muchas gracias por compartir tu experiencia %s!</p>",
        "defaultSuccessTitle": "¡Éxito!",
        "thereWasAProblem": "Hubo un problema",
        "thereWasAProblemWith": "Hay un problema con el ",
        "readOnlyModeMessage": "Estamos actualizando el sitio y momentaneamente está desactivada esta funcionalidad, vuelve a intentar dentro de 10 minutos. Perdón por las molestias :)",
        "problemNotFound": "Denuncia no encontrada.",
        "commentNotFound": "Comentario no encontrado.",
        "userNotFound": "Usuario no encontrado.",
        "tagNotFound": "La categoría de problema '%s' no existe.",
        "defaultErrorPage": "Hubo un problema cargando la página. Si se repite envía un email a %s",
        "pageNotFound": "Página no encontrada",
        "vehicleNotFound": "El vehículo que está tratando de editar no existe",
        "manualUploaded": "<strong>Recibimos el archivo :)</strong><br><br>Muchísimas gracias por subir esta información.<br>Opinautos no existiría sin este tipo de contribuciones.<br><br><strong>GRACIAS</strong>",
        "userContribution": "<strong>¡Recibido!</strong><br><br>Muchas gracias por subir esta información.<br>Opinautos no existiría sin este tipo de contribuciones.<br><br><strong>GRACIAS</strong>",
        "mpgUploaded": "<strong>Gracias por compartir tu experiencia :)</strong><br><br>Gracias a tu información vamos a poder informar el consumo real del vehículo.<br>",
        "blacklisted": "No se puede acceder al sitio",
        "checkYourCnx": "¿Su conexión a internet está bien?"
    },
    "surveys": {
        "thanks": "¡GRACIAS!",
        "usefulInformation": "¿Te sirvió esta información?",
        "qSurvey1": "¿Encontraste lo que&nbsp;buscabas?",
        "yes": "Sí",
        "no": "No",
        "meh": "Ni..."
    },
    "views": {
        "denunciaHeadline": {
            "readMore": "Leer..."
        },
        "denuncia": {
            "upvoteTitle": "Votar como útil",
            "upvoteBtn": "Votar útil",
            "downvoteTitle": "Restar voto por poco útil",
            "vote": {
                "one": "voto",
                "other": "votos"
            },
            "replies": {
                "one": "respuesta",
                "other": "respuestas"
            },
            "solutions": {
                "one": "solución",
                "other": "soluciones"
            },
            "helps": {
                "one": "ayuda",
                "other": "ayudas"
            },
            "cases": {
                "one": "caso",
                "other": "casos"
            },
            "sameProblem": {
                "one": "y otra persona con el mismo problema",
                "other": "y otras <strong class='n'>%s</strong> personas con el mismo problema"
            },
            "sameProblemCounter": {
                "one": "<span class='js-sameproblem-count'>%s</span> usuario",
                "other": "<span class='js-sameproblem-count'>%s</span> usuarios"
            },
            "otherOfftopicProblems": "Comentarios sobre otros problemas",
            "seeMoreOfftopicProblems": "Ver otros {{count}} comentarios no relacionados...",
            "reply": "Responder",
            "comment": "Comentar",
            "solvedAlt": "Solucionado",
            "solved": "Solucionado",
            "readHowWasSolved": "Leer cómo",
            "readFull": "Leer completa",
            "readMore": "Leer más",
            "edit": "Editar",
            "delete": "Borrar",
            "confirmDelete": "¿Seguro deseas eliminar tu reporte?",
            "confirmCommentDelete": "¿Seguro deseas eliminar tu comentario?",
            "fromCountry": " de %s",
            "shareYourProblem": "Cuenta lo que te pasó",
            "hadTheSameProblem": "Me pasó lo mismo",
            "flagInnapropiate": "Denunciar inapropiado",
            "madeIn": "Hecho en %s",
            "anonymousUser": "usuario anónimo",
            "markAsSolution": "Aceptar como solución al problema",
            "unmarkAsSolution": "Desmarcar como solución",
            "markedAsBestSolution": "Marcada por el autor del problema como la mejor solución",
            "accordingToAuthor": "&nbsp; (según %s, el autor del problema)&nbsp;",
            "bestSolutionAccordingTo": "Mejor respuesta (según %s)",
            "bestSolution": "Mejor solución",
            "reportComment": "Denunciar inapropiado",
            "deleteComment": "Borrar",
            "NHTSAAuthor": "Reporte de la NHTSA",
            "answersHeader": "Respuestas de la comunidad",
            "noAnswersYet": "Todavía no hay respuestas &nbsp;",
            "lockedMsg": "La conversación en este reporte está cerrada para nuevos comentarios.",
            "why": "¿Por&nbsp;qué?",
            "contactProfessional": "Contactar profesional",
            "filterModelsOfYear": "Modelos&nbsp;año&nbsp;<strong>%s</strong>",
            "seeOtherYears": "Ver&nbsp;todos&nbsp;los&nbsp;años"
        },
        "denunciaEntera": {
            "problemCategorizedAs": "Categorizado como: &nbsp;",
            "dateAndReportedBy": "Escrito %s por",
            "problemsWithModel": "Tienes problemas con un %s %s?",
            "shareYourExperience": "Cuenta tu experiencia"
        },
        "denunciasTagsResumen": {
            "popupContent": "El <strong>%s%%</strong> de los defectos del modelo (%s reportes) mencionaron problemas de <strong>%s</strong>. <div><a href='%s' class='btn'>Ver los reportes</a></div>",
            "popupTitle": "Problemas de <strong>%s</strong>",
            "otherProblems": "problemas menos comunes...",
            "allProblems": "Todos los problemas",
            "seeAllCategories": "+ Ver todas las categorías",
            "seeLessCategories": "- Ver menos categorías",
            "seeOtherTypeOfProblems": "Otro tipo de problemas...",
            "moreSpecificProblems": "Problemas más específicos:"
        },
        "errorPage": {
            "thereWasAProblem": "Hubo un problema con la página"
        },
        "elegirMarca": {
            "pickABrand": "Elige una marca:",
            "mostCommonBrands": "Marcas más comunes:",
            "allBrands": "Todas las marcas:"
        },
        "elegirModelo": {
            "pickAModel": "Elige un modelo de <strong>%s</strong>:",
            "mostCommonModels": "Más populares:",
            "allModels": "Todos los modelos:"
        },
        "help": "help/about-es",
        "privacyPolicy": "legal/privacy-policy-es",
        "termsOfUse": "legal/terms-of-use-es",
        "selectorModelo": {
            "mostPopular": "Más populares:",
            "allModels": "Todos los modelos:"
        },
        "myAccount": {
            "title": "Tu perfil en Opinautos",
            "pickAPassword": "Tadavía no has elegido una contraseña! Elige una:",
            "errorCannotAddVehicle": "Esta funcionalidad todavía está en construcción.\\n\\nTe pedimos perdón :(",
            "linkUserConfig": "Configuración de notificaciones",
            "linkModelInfo": "Información útil",
            "userData": {
                "yourData": "Tus datos",
                "yourNameIs": "Tu nombre público es",
                "nickname": "Apodo",
                "country": "País",
                "yourEmailIs": "Tu dirección de correo es",
                "yourCountryIs": "Tu país es",
                "yourCountry": "Tu país",
                "edit": "Cambiar información...",
                "editProfile": "Editar perfil",
                "updatingData": "Actualizando información...",
                "youAreUsingFBLogin": "Estás autenticandote usando la cuenta de Facebook \"<strong>%s</strong>\""
            },
            "stats": {
                "noActivty": "Todavía no tenés actividad en la comunidad"
            },
            "vehicles": {
                "yourVehicles": {
                    "one": "Tu vehículo",
                    "other": "Tus vehículos"
                },
                "modelYear": "Modelo ",
                "madeIn": "Fabricado en ",
                "youHaveNoVehicles": "No tienes ningún vehículo",
                "addNewVehicle": "Nuevo vehículo",
                "addVehicle": "Agregar un vehículo"
            },
            "problems": {
                "problems": "Problemas",
                "problemNotSolved": "Problema no resuelto",
                "goToProblem": "Ir al problema",
                "without": "sin",
                "addProblem": "Consultar problema",
                "newProblem": "Nuevo problema",
                "updateProblem": "Comentar novedades"
            },
            "contributions": {
                "yourComments": "Tus últimas respuestas",
                "profileCompletionProgress": "Perfil <strong>{{coverage}}%</strong> completo",
                "yourContributions": "Tus aportes",
                "youHaveNoContributions": "Todavía no has participado de ninguna conversación",
                "tirePressureIntro": "Busca la etiqueta en la <strong>puerta del conductor o la tapa de nafta</strong> con la presión de neumáticos",
                "carProfileIntro": "Muestra la mejor cara de tu {{model}}",
                "seeAll": "Ver todos mis comentarios",
                "approved": "APROBADO",
                "pending": "EN REVISIÓN",
                "rejected": "RECHAZADO",
                "duplicated": "DUPLICADO",
                "descPdf": "{{count}} archivos ({{fileNames}})",
                "fuseboxImage": "Imagen de tu fusiblera",
                "tirePressureImage": "Etiqueta de presión de neumáticos",
                "carProfileImage": "Foto de tu vehículo",
                "completeProfileCallToAction": "Completa datos y suma puntos"
            }
        },
        "memberPublicProfile": {
            "title": "Perfil público",
            "publicNickname": "Su nombre público es",
            "originCountry": "Es de ",
            "userSince": "Se unió a la comunidad ",
            "memberSince": "Miembro desde ",
            "userVehicles": {
                "one": "Su vehículo",
                "other": "Sus vehículos"
            },
            "statistics": "Estadísticas",
            "hisReplies": "Sus respuestas",
            "hisReports": "Sus reportes de problemas",
            "positiveVotes": "Votos",
            "negativeVotes": "Votos negativos",
            "numberOfReplies": "Cantidad",
            "numberOfReports": "Cantidad",
            "replies": "Respuestas",
            "stats": {
                "contribs": {
                    "one": "contribución",
                    "other": "contribuciones"
                }
            },
            "usersRanking": "Ranking de miembros",
            "usersRankingTwoLines": "Ranking de<br/>miembros",
            "position": "Puesto",
            "seeTop100": "Ver top 100",
            "solutions": "Soluciones",
            "denunciasPlusOnes": "Adhesiones",
            "offersProfessionalServices": "&nbsp;Ofrece servicios profesionales",
            "moreInformation": "más información",
            "hideRankings": "Menos estadísticas",
            "showMoreRankings": "Ver tu ranking en {{count}} modelos más...",
            "showMoreRankingsPublic": "Ver su ranking en {{count}} modelos más..."
        },
        "professionalProfile": {
            "shop": "Establecimiento:",
            "professional": "Profesional: %s",
            "speciality": "Especialidad",
            "contactPerson": "Persona de contacto",
            "country": "País",
            "phone": "Teléfono",
            "address": "Dirección",
            "seeContactData": "Ver datos de contacto",
            "userProfile": "Perfil en Opinautos",
            "seeCompleteProfile": "Ver perfil completo...",
            "wantToAppearAsProfessional": "¿Desea figurar como profesional?",
            "orWriteUs": "O contáctenos a <a href='mailto:profesionales@opinautos.com'>profesionales@opinautos.com</a>",
            "completeTheForm": "Complete este formulario con sus datos",
            "user": "Usuario&nbsp;"
        },
        "membersTopByScore": {
            "title": "Ranking de los {{count}} miembros de Opinautos con más contribuciones",
            "titleModel": "{{make}} {{model}}: Top 100 miembros con más contribuciones",
            "headerRank": "Ranking",
            "headerScore": "Puntos",
            "headerUser": "Usuario",
            "headerCountry": "Pais",
            "headerMemberAge": "Miembro desde",
            "headerBestAnswersAndVotes": "Soluciones y votos",
            "seeTop1000": "Ver top 1000"
        },
        "modeloComparar": {
            "otherModel": "Otro modelo...",
            "problems": "Problemas y defectos",
            "pickAModel": "Elija un modelo",
            "usuallyComparedWith": "Comparado usualmente con",
            "vs": "VS"
        },
        "modeloDefectos": {
            "problemsOf": "Problemas y fallas del <span class='marca'>%s</span> <span class='modelo'>%s</span>",
            "reportedProblems": " hay <strong class='resultCount'>%s</strong> casos reportados",
            "beMoreSpecific": "Problemas más específicos:",
            "relatedProblems": "Problemas relacionados:",
            "problemsWithA": "¿Problemas con un %s?",
            "shareAndGetHelp": "¡Compártelos y recibe ayuda!",
            "mostCommonProblems": "Problemas comunes",
            "mostRecentProblems": "Últimos problemas",
            "problemsWithNoAnswers": "Problemas sin respuesta",
            "shareThem": "Compártelos",
            "problemsByCategory": "Problemas por categoría",
            "otherTagProblems": "Otros problemas de %s",
            "otherTypeOfProblems": "Otros tipos de problemas",
            "seeProblemsWithNoAnswers": "ver sin respuesta",
            "seeOwnersManual": "Ver manual del propietario"
        },
        "modeloCommunity": {
            "title": "Comunidad del {{make}} {{model}}",
            "lastPosts": "Últimas preguntas",
            "postsWithoutComments": "Preguntas destacadas sin respuesta",
            "infographic": {
                "fuseboxDiagram_line1": "diagramas",
                "fuseboxDiagram_line2": "de fusibles"
            }
        },
        "modeloDefectosPorTipo": {
            "problemsOfType": "Problemas y fallas de <span id='currentTag'>{{tag}}</span> del <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "problemsOfTypeShort": "Problemas de <span id='currentTag'>{{tag}}</span> del <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "problemsOfTypeShortV2": "Problemas de <span id='currentTag'>{{tag}}</span>",
            "thereAreNReports": "Hay <strong>%s</strong> casos reportados",
            "allProblems": "Todos los problemas",
            "bestProblems": "Problemas destacados",
            "bestProblemsOf": "Problemas destacados de %s",
            "latestProblems": "Problemas recientes",
            "seeBestProblems": "ver destacados",
            "seeLatestProblems": "ver recientes",
            "seeAllProblems": "Ver los {{count}} problemas de {{tag}}",
            "seeOwnersManual": "Ver manual del propietario",
            "seeFuseboxDiagram": "Ver diagrama de fusibles",
            "tagsWithFusebox": [
                "fusibles",
                "reles",
                "radio",
                "alarma",
                "luz-de-abs",
                "luz-de-hold",
                "luz-de-tc",
                "luz-de-od-off",
                "luz-de-check-gages",
                "luz-de-eps",
                "luz-de-check-engine",
                "luz-del-airbag",
                "llave-de-encendido",
                "luces-intermitentes-balizas",
                "luces-antiniebla",
                "tablero-no-enciende",
                "luz-de-freno",
                "luz-de-epc",
                "luces-testigo",
                "tablero",
                "luces-parpadean",
                "luz-de-aceite",
                "prende-luz-de-inyeccion",
                "luz-de-aceite",
                ""
            ]
        },
        "modeloDefectoThread": {},
        "modeloResumen": {
            "people": "personas",
            "reports": "casos",
            "ofProblemsAndSolutions": " de problemas y fallas, soluciones y experiencias valiosas de otros dueños",
            "modelOverviewOf": "Información útil del&nbsp;%s&nbsp;%s",
            "problems": "Problemas y soluciones",
            "addedProblems": " agregaron defectos, problemas y fallas",
            "searchYourProblem": "Busca tu problema",
            "solveYourProblem": "pide ayuda",
            "shareYourExperience": "Comparte tu experiencia o problema",
            "seeMoreProblems": "Ver más reportes de problemas...",
            "seeMore": "Ver más",
            "noReportsYet": "Todavía nadie contó su experiencia o pidió ayuda con algún problema.",
            "wannaBeTheFirst": "¿Quieres ser el primero?",
            "notComparedYet": "Nadie comparó este modelo todavía.",
            "compareModelWith": "Comparar <strong>%s %s</strong> con otro modelo...",
            "compareWithOtherModel": "Comparar con otro modelo",
            "ownersManual": "Descargar el manual del propietario del %s&nbsp;%s",
            "serviceManual": "Manuales de taller, reparación y otras cosas útiles",
            "warningLights": "Luces del tablero más comunes en el %s explicadas ",
            "fuseBox": "Explora un diagrama interactivo de la caja&nbsp;de&nbsp;fusibles de tu %s %s",
            "guides": "Encontrá información de expertos sobre Cambios de aceite, la luz de Check Engine y más",
            "reviewsDescription": "Lo que los dueños reales de {{model}} opinan de sus vehículos, lo bueno y lo malo"
        },
        "modelReviews": {
            "title": "Opiniones sobre el {{model}}",
            "subtitle": "Últimas opiniones de dueños de {{model}}",
            "doYouOwnOne": "¿Eres dueño?",
            "weAreLookingForReviews": "¡Ayuda a otras personas que quieren conocer tu opinión!",
            "errorYouAlreadyMadeAReview": "Sólo se puede dejar una opinión sobre cada vehículo, y tú ya has dejado una reseña sobre tu {{model}}.<br><br><a href='{{reviewUrl}}'>Puedes leer esa opinión aquí</a>",
            "leaveYourReview": "Deja tu opinión",
            "liked": "Lo mejor:",
            "disliked": "Lo peor:",
            "averageTitle": "Promedio de <strong>{{count}} opiniones</strong>:",
            "summary": {
                "positive": "✅ Lo positivo más mencionado",
                "negative": "⛔ Lo negativo más mencionado"
            }
        },
        "relatedProblems": {
            "relatedProblems": "Problemas relacionados",
            "inOtherModels": "En otros modelos"
        },
        "registracion": {
            "title": "Crear una cuenta en Opinautos",
            "join": "Crear mi cuenta",
            "youAreAlreadyRegistered": "¡Ya estás registrado en Opinautos!<br/>Tu usuario es '<strong>%s</strong>'",
            "seeAccountInfo": "Ver información sobre tu cuenta"
        },
        "registracionOauth": {
            "title": "No existe ningún usuario asociado a esa cuenta de Facebook",
            "didYouHaveAnAccount": "¿Ya estabas registrado en Opinautos?",
            "nope": "No...",
            "yep": "Si...",
            "ifYouAreNewCreateAnAccount": "Si eres nuevo en Opinautos y no estás registrado, crea una cuenta haciendo click aquí:",
            "createAnAccount": "Crear cuenta en Opinautos",
            "ifHadAnAccountConnect": "Si ya tenías un usuario en opinautos y deseas asociarlo a esta cuenta de Facebook, ingresa el email y contraseña de ese usuario y haz click en \"Conectar con mi cuenta de Facebook\"",
            "connectWithFacebook": "Conectar con mi cuenta de Facebook"
        },
        "registracionOauthEmailTaken": {
            "title": "Ya existe un usuario registrado usando el email '%s' <br>",
            "sameFacebookEmail": "Ese mismo email es el que usa tu cuenta de Facebook",
            "ifHadAnAccountWithEmail": "Si ya tenías un usuario en opinautos usando el email '%s' y deseas asociarlo a esta cuenta de Facebook, ingresa la contraseña de ese usuario y haz click en \"Conectar con mi cuenta de Facebook\"",
            "ifForgotPassword": "Si olvidaste tu contraseña, haz click aquí: "
        },
        "selectorMarcaModelo": {
            "errorLoadingModels": "Hubo un problema cargando modelos: ",
            "pickModelOf": "Elija un modelo de "
        },
        "userConfig": {
            "title": "Notificaciones por email",
            "emailCheckboxEngagement": "Recibir recordatorios, estadísticas de la cuenta y otra información relevante periódicamente",
            "emailCheckboxTransactional": "Recibir soluciones y respuestas a mis problemas y comentarios"
        },
        "userPanel": {
            "myAccount": "Mi Cuenta",
            "logOut": "Salir",
            "areYouNew": "¿Eres nuevo?",
            "signUp": "Crear cuenta",
            "logInOrSignUp": "Ingresar o registrarse",
            "createAccount": "Crea tu cuenta"
        },
        "userNotices": {
            "needToPickPassword": "<strong>%s</strong>&nbsp; Te falta elegir una contraseña para tu usuario&nbsp;",
            "pickPassword": "Elegir contraseña",
            "passwordSaved": "La contraseña se guardó exitosamente",
            "weSentYouAConfirmationEmail": "Te escribimos a <strong>%s</strong> para&nbsp;confirmar&nbsp;tu&nbsp;nuevo&nbsp;usuario",
            "addressIsCorrect": "No me llegó el email",
            "addressIsNotRight": "El email está mal escrito"
        },
        "formReportDenuncia": {
            "title": "¡Ayúdanos a ordenar la comunidad!"
        },
        "resetPasswordExpired": {
            "description": "El pedido por pérdida de tu contraseña ha expirado (pasaron más de 24 horas)",
            "makeNewRequest": "Haz un nuevo pedido haciendo click aquí"
        },
        "searchResults": {
            "youSearched": "Resultados de ",
            "searchInstead": "Buscar, en cambio, ",
            "resultsFound": "<strong>{{count}}</strong> casos relacionados:",
            "noResultsFound": "No se encontraron casos relacionados"
        },
        "login": {
            "youAreLoggedAs": "Estás logueado como el usuario '%s'. Puedes ver ",
            "thisAccountsInfo": "información de esta cuenta",
            "logInAsOtherUser": "ingresar con un usuario distinto",
            "orYouCan": " o puedes ",
            "newUser": "¿Nuevo usuario?"
        },
        "infoOwnersManual": {
            "downloadPDF": "Descargar PDF",
            "downloadManualInPDF": "Descargar manual en PDF",
            "findBelowExplanation": "A continuación puedes descargar gratuitamente el manual del propietario de tu {{make}} {{model}} en PDF.<br> Manuales para los años {{minYear}} a {{maxYear}} ",
            "currentlyDoesntExist": "Por el momento no contamos con manuales del {{make}} {{model}}.",
            "currentlyDoesntExistYear": "No contamos con manuales del {{make}} {{model}} para el año {{year}}.",
            "forTrims": "Para {{model}} {{year}} {{trims}}",
            "letUsKnow": "Pídelo aquí",
            "uploadIt": "Súbelo y compártelo",
            "lookingForOther": "¿Buscando otro año u otro modelo?&nbsp;",
            "canYouContribute": "¿Tienes algún manual en PDF para compartir?&nbsp;",
            "editionFromCountry": "Edición de {{country}}",
            "totalPages": "{{pages}}&nbsp;páginas",
            "modelOwnersManual": "Manual&nbsp;del&nbsp;propietario {{make}}&nbsp;{{model}}&nbsp;<strong>{{year}}</strong>",
            "modelOtherManual": "{{category}} {{make}}&nbsp;{{model}}&nbsp;{{year}}",
            "title": "Manual del <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "titleYear": "Manual del <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>&nbsp;{{year}}",
            "titleRepairOther": "Manuales de taller y reparación <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "manualInSections": "Manual dividido en {{count}} secciones",
            "manualEditions": {
                "one": "Ver una edición anterior de este manual",
                "other": "Ver {{count}} ediciones anteriores de este manual"
            },
            "sectionNoName": "Otros"
        },
        "infoFusebox": {
            "title": "Diagrama de fusibles y relés <span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span>",
            "titleYear": "Diagrama de fusibles de {{make}} {{model}} {{year}}",
            "yearDifferentFuseboxes": "Este {{model}} tiene {{count}} cajas de fusibles distintas:",
            "diagramBoxName": "Diagrama de {{box}}",
            "pickYearReminder": "Los diagramas de fusibles del {{make}} {{model}} cambian con los años, selecciona el año de tu vehículo:&#32;",
            "relayNo": "Relé",
            "fuseNo": "Fusible nro",
            "fusetableHeaderNo": "Nro",
            "fusetableHeaderType": "Tipo",
            "fusetableHeaderDescription": "Descripción",
            "source": "Fuente",
            "tapForMoreFuseInfo": "<strong>Toca sobre un fusible </strong> para ver más información",
            "exploreInteractiveFusebox": "Vea diagramas de fusibles interactivos de {{make}} {{model}}. Las cajas de fusibles cambian con los años, elija el año de su vehículo:&#32;",
            "nofuseboxYet": "Todavía no publicamos diagramas para el <strong>{{make}} {{model}}</strong>, pero sube una foto de tu fusiblera y te lo conseguimos",
            "fuseboxDiagramYear": "Diagramas&nbsp;de&nbsp;fusibles<br/><span class='year'>{{model}} {{year}}<br/><span class='other'>{{other}}</span></span>",
            "feedbackMistakeInvitation": "¿Encontraste&nbsp;un&nbsp;error? &nbsp;",
            "askForYourFusebox": "¿Falta el de tu auto o año?",
            "wrongFusebox": "¿No coincide con tu auto?",
            "askForItWeHelpYou": "Pedir diagrama",
            "letUsKnow": "¡Avísanos!",
            "onboardingTouchFuse": "👆 Toca un fusible para ver su función.<br/><br/>↓ Tienes la tabla abajo",
            "onboardingTouchTable": "👆 Toca un fusible de la lista para ubicarlo en el diagrama"
        },
        "infoFuseboxIndividual": {
            "locationTitle": "Ubicación del {{fuse}} del {{make}} {{model}} {{year}}",
            "locationDescriptionMoreThanOneBox": "Este {{model}} tiene {{boxesCount}} cajas de fusibles distintas donde se ubica el {{fuse}}:",
            "linkFullFusebox": "Ver listado completo de fusibles y relés...",
            "relatedProblems": "Problemas comunes con el {{fuse}}",
            "stillHaveDoubts": "¿Todavía tienes dudas con tu problema?<br>¡Pregúntale a la comunidad!",
            "askForHelp": "Pide ayuda",
            "problemsLink": "Ver problemas con el {{fuse}}",
            "filteredTableHeader": "Fusibles y relés relacionados con {{fuseEntity}}:"
        },
        "infoWarningLights": {
            "title": "Significado de las luces del tablero del <span class='marca'>{{make}}</span>&nbsp;<span class='modelo'>{{model}}</span>",
            "readRelatedReportsSingular": "Ver <span><b>{{tagCount}}</span> reporte</b> de {{tag}} en el <span>{{model}}</span>",
            "readRelatedReportsPlural": "Ver <span><b>{{tagCount}}</span> reportes</b> de {{tag}} en el <span>{{model}}</span>",
            "getHelpWith": "<b>Obtén ayuda</b> con &#32",
            "pickALight": "Elige una luz para ver qué significa:",
            "getHelpWithWarningLight": "<b>Obtén ayuda</b> con &#32",
            "infoSource": "Fuente: manual de usuario del <span class='marca'>{{make}}</span> <span class='modelo'>{{model}}</span>",
            "lookingForSomethingElse": "<b>¿Buscando otra luz testigo u otro modelo?</b>&nbsp;&nbsp;",
            "letUsKnow": "¡Coméntanos!"
        },
        "infoTirePressure": {
            "accordingToSpecs": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Presiones detalladas</span>",
            "validForOriginalTires": "* Sólo neumáticos originales",
            "spareTire": "Rueda de auxilio:",
            "front": "adelante",
            "rear": "atrás",
            "summaryTitle": "{makeLogo}&nbsp;&nbsp;<span class=\"align-middle\">Resumen presión {makeOnly} {makeOrVehicle} </span>&nbsp; {countryFlag}",
            "defaultPressureUnit": "psi",
            "conditions": {
                "highway": "En rutas",
                "no-highway": "En calles",
                "loadHigh": "Con carga alta",
                "loadNormal": "Con carga normal",
                "all": "Con carga normal",
                "comfort": "Más comodidad",
                "economic": "Ahorro combustible",
                "trailer": "Con trailer"
            }
        },
        "articles": {
            "relatedProblems": "Problemas con {{article}} en {{make}} {{model}}",
            "articlesIndexTitle": "Guías útiles para el {{make}}&nbsp;{{model}}",
            "readMoreAboutMakeAndTopic": "Leer más sobre modelos {{make}} <br> y {{topic}}",
            "readMoreAboutTopic": "Información más general sobre<br> {{topic}} en un vehículo",
            "relatedArticles": "Artículos relacionados",
            "makeModelsWithMoreProblems": "Modelos de {{make}} con más problemas de {{topic}}",
            "makeProblemsCount": "{{count}} problemas",
            "haveYouFoundAnError": "¿Has encontrado un error en esta guía? Avísanos",
            "feedbackProblemsWithThisGuide": "¿Problemas con esta guía?",
            "feedbackProblemsWriteHere": "Escríbenos aquí...",
            "templateVehicle": "  vehículo",
            "templateManualLinkClickHere": "click aquí",
            "templateManualLinkOwnerManual": "manual de usuario",
            "articleNotFound": "No se encuentra la guía '{{article}}'"
        },
        "filters": {
            "filters": "Filtros"
        },
        "scoreChanges": {
            "title": "¡Gracias por colaborar {{username}}!",
            "subTitle": "Recientemente sumaste",
            "rankingUp": "Acabas de sumar <strong>{{scorePhrase}}</strong> y subir <strong>{{position}}&nbsp;{{positionPhrase}}</strong> en el <a href='{{rankingUrl}}'>ranking de miembros</a>",
            "positionTitle": "Estás en el <br>puesto",
            "positionText": "Acabas de sumar <strong>{{score}}&nbsp;{{scorePhrase}}</strong> para el <a href='{{rankingUrl}}'>ranking de miembros</a>",
            "congratulations": "¡Felicitaciones!",
            "points": {
                "one": "punto",
                "other": "puntos"
            },
            "positions": {
                "one": "posicion",
                "other": "posiciones"
            },
            "cause": {
                "commentsVotesPositive": {
                    "one": "voto de respuesta útil",
                    "other": "votos de respuesta útil"
                },
                "denunciasVotesPositive": {
                    "one": "voto de reporte útil",
                    "other": "votos de reporte útil"
                },
                "commentsMarkedAsSolutionCount": {
                    "one": "respuesta aceptada como solución",
                    "other": "respuestas aceptadas como soluciones"
                },
                "denunciasPlusOnes": {
                    "one": "persona con el mismo problema",
                    "other": "personas con el mismo problema"
                },
                "commentsCount": {
                    "one": "respuesta",
                    "other": "respuestas"
                },
                "contributionsApproved": {
                    "one": "contribución",
                    "other": "contribuciones"
                }
            }
        },
        "modelTrims": {
            "title": "Versiones del {{make}} {{model}} en {{country}}",
            "titleGlobal": "Versiones del {{make}} {{model}}",
            "subtitle": "Años en los que se vendió el {{make}}&nbsp;{{model}}&nbsp;en&nbsp;{{country}}:",
            "subtitleNotInCountry": "No tenemos información del {{make}}&nbsp;{{model}}&nbsp;en&nbsp;{{country}}",
            "feedbackMistakeInvitation": "¿Se vendió en algún otro año? ¿Hay&nbsp;un&nbsp;error? &nbsp;",
            "trimInCountry": "{{make}} {{model}} en {{country}}",
            "versions": "Versiones {{model}} <span class='year'>{{year}}</span>"
        },
        "modelYearTrims": {
            "title": "Versiones del {{model}} {{year}} en {{country}}",
            "subtitle": "Versiones de {{make}} {{model}} que se vendieron en {{year}} en {{country}}. ",
            "subtitleYearNotSold": "El {{make}} {{model}} <strong>{{year}}</strong> no se vendió en {{country}}. ",
            "feedbackMistakeInvitation": "¿Falta una versión? ¿Hay&nbsp;un&nbsp;error? &nbsp;",
            "seeOtherYears": "Para otros años haz click aquí",
            "trimsInOtherCountries": "Versiones del {{model}} en otros países:"
        }
    },
    "forms": {
        "common": {
            "topUserCountries": "ar\nbr\nes\nmx",
            "topCarCountries": "ar\nbr\nes\nmx",
            "carCountries": "de\nar\nau\nat\nbe\nbr\nca\nco\ncn\nkr\neg\nsi\nsi\nes\nus\nus\nfr\nnl\nhu\nin\nid\nir\nit\njp\nmy\nmx\npe\npl\npt\ngb\ncz\nro\nru\nrs\nza\nse\nth\ntw\ntr\nua\nuz",
            "userCountries": "de\nad\nar\nbz\nbo\nbr\nca\ncl\nco\ncr\ncu\nec\nsv\nes\nus\nfr\ngt\ngf\nhn\nit\nmx\nni\npa\npy\npe\npt\npr\ndo\nsr\nuy\nve",
            "countryOther": "OTRO",
            "checkAllFields": "Revise que esté todo completo",
            "spellcheckLang": "es",
            "errorUserNotAuthorized": "Usuario no autorizado a realizar esa operación"
        },
        "modelSelector": {
            "brandAndModelLabel": "Marca y Modelo",
            "modelExtrasLabel": "Versión",
            "modelExtrasExample": "Ej: 4 puertas, full, motor 1.7",
            "yearLabel": "Año modelo",
            "pickYear": "Elija el año",
            "yearExample": "Ej: 1999, 2003, 2012...",
            "madeInLabel": "Fabricado en",
            "carUsageLabel": "Kms de uso",
            "carUsageExample": "Ej: 0, 250, 5000",
            "carUsageUnit": "km",
            "changeBrandOrModel": "Otro <br> modelo",
            "pickBrand": "Elige una marca",
            "cantFindMyModel": "No encuentro mi marca o modelo",
            "cantFindMyModelModalMsg": "Deseo avisar que en la lista de marcas y modelos falta:"
        },
        "login": {
            "loginError": "No se pudo autenticar el usuario. Revisa que el email y la contraseña sean correctos.",
            "facebookLoginError": "Hubo un problema autenticando al usuario.",
            "facebookAuthFailed": "Hubo un problema autenticando con Facebook",
            "facebookNotRegisteredError": "No estás registrado en el sitio",
            "signInWithFacebook": "Regístrate con Facebook",
            "logInWithFacebook": "Ingresa con Facebook",
            "forgotPassword": "Olvidé mi contraseña",
            "onlyForRegisteredUsers": "Esta página es para miembros registrados. Si ya estás registrado, ingresa al sitio:"
        },
        "setPassword": {
            "title": "¡Elige una contraseña para poder seguir entrando al sitio!",
            "repeatPassword": "Repita la contraseña",
            "savePassword": "¡Guardar contraseña!"
        },
        "wrongEmail": {
            "updateEmail": "Actualizar email",
            "myEmailIsNot": "Mi email NO es  <em>%s</em>",
            "errorSameEmail": "Es el mismo email de antes, corríjelo",
            "insteadItIs": "sino",
            "title": "Corregir dirección de correo"
        },
        "registration": {
            "youLogIn": "Ingresa",
            "email": "Email",
            "logIn": "Ingresar",
            "logIn2": "Iniciar sesión",
            "forgotPassword": "Olvidé mi <br> contraseña",
            "password": "Contraseña",
            "logOut": "Cerrar sesión",
            "doYouHaveAnAccount": "¿Ya tienes una cuenta?",
            "errorAddingUser": "Hubo un problema agregando el usuario",
            "errorUserAlreadyExists": "Ya estás registrado con el usuario %s",
            "errorEmailAlreadyExists": "Ya existe un usuario registrado con ese email.<br>Si eres tú, en vez de registrarte debes ingresar al sitio con tu contraseña. Puedes hacerlo clickeando en el botón de Ingresar que se encuentra arriba a la derecha.",
            "errorTitleEmailAlreadyExists": "¡Ya existe %s!"
        },
        "userRegistration": {
            "nicknameLabel": "Nombre de usuario",
            "nickNameExplanation": "La firma que verán los demás",
            "userCountryExplanation": "Tu país",
            "userCountryPlaceholder": "Elige tu país",
            "emailLabel": "o con tu Email",
            "emailLabelReply": "Tu Email",
            "emailExplanation": "Es para manejar tu usuario en el sitio y avisarte cuando otro usuario te responde o te hace una pregunta."
        },
        "connectAccounts": {
            "errorCouldNotAuthenticate": "No se pudo autenticar el usuario. Revise que el nombre sea correcto y la contraseña también.<br><br>"
        },
        "feedback": {
            "title": "Envíanos comentarios sobre el sitio",
            "contentPlaceholder": "¿Tienes comentarios, ideas o sugerencias? Escríbenos aquí",
            "emailDescription": "Email para contactarte (opcional)",
            "send": "Enviar comentarios",
            "feedbackTooShort": "El comentario tiene que tener al menos 10 letras"
        },
        "passwordReset": {
            "unknownEmail": "El email no pertenece a ningún usuario registrado",
            "requestExpired": "El pedido por pérdida de contraseña expiró, realice uno nuevo.",
            "userNotFound": "El password reset pertenece a un usuario inexistente.",
            "userNotConfirmed": "El usuario todavía no está confirmado",
            "sentExplanation": "<br>Te hemos enviado un email a <u>{{email}}</u> con un link para que elijas una nueva contraseña.<br> ¡Chequea tu casilla de correo!",
            "noEmailFilled": "<strong>No has completado un email</strong>",
            "sendRequest": "Enviar email para nueva contraseña",
            "enterYourEmail": "Ingresa la dirección de correo con la que te has registrado:",
            "youAreNotConfirmedYet": "Todavía no tienes una contraseña porque no has confirmado tu email!",
            "lookForTheConfirmationEmail": "Busca el email que te enviamos a <em>%s</em> y <strong>haz click en el botón</strong> que dice <em>\"Confirmar que soy un usuario legítimo\"</em>.",
            "pickANewPassword": "Elige una contraseña para poder seguir entrando al sitio!",
            "saveNewPassword": "Guardar nueva contraseña!"
        },
        "emailconfirmation": {
            "invalidToken": "Identificador de confirmación inválido",
            "alreadyConfirmed": "Usuario ya confirmado",
            "thanksForConfirming": "<strong>%s</strong>, gracias por confirmar tu dirección de email.",
            "errorFailed": "La confirmación falló: %s",
            "errorInvalidLink": "Link de confirmación inválido."
        },
        "changeEmail": {
            "alreadyTaken": "Esa dirección de correo ya está en uso.",
            "userHadNoEmail": "El usuario no tenía email."
        },
        "vote": {
            "errorAlreadyVotedComment": "Ya votó esa respuesta. No se puede votar más de una vez.",
            "errorSelfVoteComment": "No se puede votar sus propias respuestas.",
            "errorAlreadyVoted": "Ya votó ese problema. No se puede votar más de una vez.",
            "errorSelfVote": "No se puede votar sus propios reportes."
        },
        "agregarDenuncia": {
            "errorInvalidProblemId": "id de denuncia '%s' inválido.",
            "errorDuplicate": "Ya existe un reporte exactamente igual a ese:<br><br> <a href='%s'>%s</a><br><br>¿puede ser que ya hayas agregado esta denuncia?",
            "validations": {
                "editedTitleTooLong": "tituloEditado demasiado largo",
                "editedTitleTooShort": "tituloEditado demasiado corto",
                "editedContentTooShort": "contenidoEditado demasiado corto",
                "editedContentTooLong": "contenidoEditado demasiado largo"
            },
            "sectionVehicle": "Tu vehículo",
            "sectionPickVehicle": "Elige tu vehículo",
            "sectionProblem": "¿Cuál es el problema?",
            "sectionAccount": "Recibe la respuesta",
            "orAddANewVehicle": "o puedes agregar un nuevo vehículo",
            "toUseOtherVehicleClick": "(para usar otro de tus vehículos haz click aquí)",
            "emailRequiredExplanation": "Necesitamos tu email para avisarte cuando te respondan y para que puedas administrar tu cuenta.",
            "signature": "Firma",
            "vehicleCountryPlaceholder": "Elige un país",
            "titlePlaceholder": "Título del problema",
            "contentPlaceholder": "Describe lo que buscas\n\n   ¿se prendió una luz? ¿hace algún ruido?\n   ¿intentaste alguna solución?\n",
            "ifRegistered": "Si ya tienes una cuenta,",
            "loginWithIt": "ingresa con ella",
            "addProblem": "Enviar",
            "floatingAddText": "Preguntar"
        },
        "addReview": {
            "yourScore": "¿Qué puntaje le darías?",
            "buttonNext": "Siguiente",
            "buttonBack": "Atrás",
            "sectionYourVehicle": "Opina sobre tu vehículo",
            "sectionYourExperience": "En tu experiencia:",
            "sectionLiked": "Lo que más te gusta",
            "sectionDisliked": "Lo que no te gusta",
            "likedPlaceholder": "¿Qué es lo mejor de tu vehículo?",
            "dislikedPlaceholder": "¿Qué es lo peor de tu vehículo?",
            "sectionCreateUser": "Crea un usuario",
            "submitReview": "Enviar opinión",
            "orAddANewVehicle": "agrega otro vehículo"
        },
        "responderDenuncia": {
            "errorDuplicate": "Ya existe un comentario con el mismo contenido!<br><br>Si no ve un comentario que acaba de hacer, pruebe recargando la página ;)",
            "errorLocked": "El reporte en el cual intenta responder está cerrado para nuevos comentarios.",
            "errorNoParent": "El comentario que intenta responder ya no existe más",
            "errorSubSubComment": "No se puede responder un subcomentario de un comentario",
            "areaReplyPlaceholder": "Escribe aquí tu respuesta",
            "areaCommentPlaceholder": "Escribe aquí tus comentarios",
            "solvedTheProblem": "Resolví mi problema o consulta",
            "submitReply": "Enviar respuesta",
            "submitComment": "Enviar comentario",
            "solvedIt": "Ya lo solucioné",
            "notSolvedYet": "Sigue sin solución"
        },
        "editComment": {
            "noChanges": "No hubo ningún cambio en el comentario editado.",
            "errorInvalidIndex": "Indice de comentario inválido",
            "areaPlaceholder": "Respuesta.",
            "errParentNotFound": "No existe el comentario parent",
            "errParentNewerThanChild": "El comentario padre no puede ser más reciente que el hijo",
            "errParentIsChild": "No se pueden anidar más de dos niveles",
            "errParentSameAsChild": "Un comment no puede ser hijo de si mismo",
            "errCannotDestroyComment": "Puedes agregar lo que necesites al comentario, pero para borrarlo o editarlo por completo contáctanos a: <a href='mailto:moderadores@opinautos.com'>moderadores@opinautos.com</a>"
        },
        "editDenuncia": {
            "noChanges": "No hubo ningún cambio en la denuncia editada.",
            "checkAllFields": "Revise que esté todo completo",
            "errorUserNotAuthorized": "Usuario no autorizado a editar ese reporte",
            "errCannotDestroyDenuncia": "Puedes agregar lo que necesites a la denuncia, pero para borrarla o editarla por completo contáctanos a: <a href='mailto:moderadores@opinautos.com'>moderadores@opinautos.com</a>"
        },
        "reportComment": {
            "title": "¡Ayúdanos a ordenar la comunidad!",
            "explanation": "Denuncia este comentario si contiene:",
            "examples": "<ul> <li>Agresiones o burlas</li> <li>Publicidad o spam</li> <li>Indicaciones incorrectas o peligrosas</li> <li>Cosas que no deberían estar aquí</li> </ul>",
            "areaReasonPlaceholder": "Escribe el motivo...",
            "report": "Denunciar"
        },
        "reportDenuncia": {
            "title": "¡Ayúdanos a ordenar la comunidad!",
            "explanation": "Denuncia este defecto si contiene:"
        },
        "noConfirmation": {
            "title": "Si no llegó el email de confirmación...",
            "check1": " Revisa que tu dirección de correo esté bien escrita: <strong>%s</strong>",
            "check2": " Revisa que el email no esté en la carpeta de <strong>correo no deseado</strong> (o spam).<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(El asunto del email es '<em>Confirmá tu email en Opinautos</em>')",
            "check3": " Sino, escríbenos un correo a <strong><a href='mailto:%s'>%s</a></strong> con asunto '<em>No llegó confirmación</em>'"
        },
        "formNoConfirmation": {
            "title": "Si no llegó el email de confirmación..."
        },
        "formWrongEmail": {
            "title": "Corregir dirección de correo"
        },
        "formSurveyFollowUp": {
            "title": "¿Cómo podríamos mejorar?"
        },
        "noAnswers": {
            "title": "¿Por qué algunas preguntas no tienen respuestas?",
            "explanation": "Opinautos es una comunidad donde la ayuda y las respuestas son dadas por la misma comunidad, formada por otros <strong>dueños, aficionados, mecánicos</strong>, etc.<br><br>A veces lleva un tiempo hasta que un miembro del sitio ve una pregunta en la que puede colaborar y comenta. <br><br> Mientras tanto, gracias por entender y <strong>te invitamos a colaborar</strong> donde puedas!!"
        },
        "sameProblem": {
            "errorAlreadyOwner": "No se puede adherir a su propio problema",
            "errorAlreadyMarked": "Ya estaba adherido al problema",
            "errorNothingToRemove": "No estaba adherido al problema del que intenta desuscribirse"
        },
        "imageUpload": {
            "upload": "Clickea para subir una foto o&nbsp;arrastrala",
            "dropzone": "Arrastra y suelta las imágenes aquí",
            "dropzoneHover": "Suelta las imágenes",
            "viewOriginal": "Ver original",
            "policy": {
                "title": "<h3>Uso de imágenes</h3>",
                "yesExplanation": "<strong>Sí</strong> agregar fotos del problema o la solución que ayuden a los demás",
                "noExplanation": "<strong>No</strong> poner fotos de perfil o de tu auto que no aportan a la discusión"
            },
            "uploadYourPhoto": "Subir mi foto",
            "submitContrib": "Enviar contribución",
            "uploading": "Subiendo..."
        },
        "pdfUpload": {
            "title": "Subir manuales en PDF",
            "upload": "Haz click para subir un archivo PDF o&nbsp;arrastrelo aquí",
            "dropzone": "Arrastra y suelta el archivo aquí",
            "dropzoneHover": "Suelta los archivos",
            "submitFiles": "Subir archivos"
        },
        "ShareMPG": {
            "title": "¿Cuánto consume tu&nbsp;{{modelo}}?",
            "explanation": "Compartilo así te podés comparar con otros dueños",
            "dontKnow": "No lo medí",
            "shareMpg": "Compartir"
        },
        "switchCountry": {
            "title": "Navegar en el sitio de",
            "nowYouAreInVersion": "Ahora estás navegando<br/>en Opinautos {{country}}"
        },
        "pickMake": {
            "searchYourMake": "Busca tu marca",
            "whatMakeIsYourCar": "¿De qué marca es tu vehículo?"
        },
        "pickModel": {
            "searchYourModel": "Busca tu modelo",
            "whatModelIsYourCar": "¿Qué modelo de {{make}}?"
        },
        "pickFuseboxProblem": {
            "didYouFindYourDiagram": "¿Cómo podemos ayudarte?",
            "FUSEBOX_NOT_FOUND": "No hay diagrama",
            "YEAR_NOT_FOUND": "Hay diagrama pero no de mi año",
            "DIFFERENT_FUSES": "Los colores o fusibles son muy distintos",
            "FUSE_NOT_FOUND": "No encuentro un fusible",
            "OTHER": "Otro"
        },
        "pickVehicle": {
            "whatYearIsYourModel": "¿De qué año es tu {{model}}?",
            "whichOfThisVersion": "¿Cuál de estas versiones?",
            "other": "Otro",
            "otherTrim": "Otra versión...",
            "whichVersion": "¿Qué versión?",
            "saveVehicle": "Guardar vehículo",
            "dontKnow": "No sé",
            "otherMakeModel": "Otra marca o modelo..."
        },
        "contribFuseboxPicture": {
            "modalTitleAskDiagram": "Pedir diagrama de fusibles",
            "titleFindDiagram": "Con una foto de tu fusiblera podemos conseguir el diagrama",
            "titleHelpUs": "Ayudanos a mejorar el diagrama con una foto de tu fusiblera"
        },
        "contribImage": {
            "popupBarTitle": "Suma tu aporte",
            "thanksForTheUpload": "¡Muchas gracias por sumar!",
            "registerSoWeCanContactYou": "Crea tu cuenta en Opinautos.com así te podemos contactar:",
            "imageWillBeChecked": "Tu foto es el primer paso para obtener el diagrama. Ya estamos trabajando en ello, haremos lo posible por tenerlo listo cuanto antes 🔧",
            "tirePressureLabelThx": "Vamos a procesar tu foto para responder a todos los que nos preguntaban cuál era la presión de neumáticos correcta 🔧",
            "carProfileThx": "Gracias por compartir tu foto 🔧",
            "anyExtraComment": "¿Algo que quieras agregar?",
            "extraCommentPlaceholder": "Cualquier comentario que nos sirva para mejorar los diagramas..."
        },
        "completeVehicleProfile": {
            "title": "Completa el perfil de tu vehículo",
            "actionDesc": {
                "review": "Deja una opinión sobre tu auto: lo bueno y lo malo",
                "profile": "Especifica el modelo y versión de tu auto",
                "profilePic": "Comparte una foto de tu vehiculo",
                "tirepressure": "Saca una foto de la etiqueta con la presión de los neumáticos"
            }
        }
    },
    "static": {
        "domain": "//static.opinautos.com",
        "images": {
            "logoLargeWhite": "/images/design2/opinautos-logo-white-large.png",
            "logoHeaderWhite": "/images/design2/opinautos-logo-white-header.png",
            "logoSmall": "/images/design2/opinautos-logo-dark-large.png",
            "allMakesImage": "/images/makes-medium-all-tiny.png",
            "allMakesImageMapping": "abarth\nacura\nalfa-romeo\naro\naudi\naustin\nbaic\nbmw\nbuick\nbyd\ncadillac\nchangan\nchery\nchevrolet\nchrysler\ncitroen\ncupra\ndacia\ndaewoo\ndaihatsu\ndatsun\ndodge\ndongfeng\nds\nfaw\nferrari\nfiat\nford\nfoton\ngalloper\ngeely\ngenesis\ngmc\ngreat-wall\nhaval\nhonda\nhummer\nhyundai\nika\ninfiniti\nisuzu\niveco\njac\njaguar\njeep\nkia\nlada\nlancia\nlandrover\nlexus\nlifan\nlincoln\nlotus\nmahindra\nmaserati\nmazda\nmercedes-benz\nmercury\nmini\nmitsubishi\nnissan\noldsmobile\noltcit\nopel\npeugeot\nplymouth\npontiac\nporsche\nproton\nram\nrenault\nrover\nrenault-samsung\nsaab\nsantana\nsaturn\nscion\nseat\nskoda\nsmart\nssangyong\nsubaru\nsuzuki\nswm\ntata\ntesla\ntoyota\nvolkswagen\nvolvo\ndfsk\nbentley\nshineray\n",
            "topQuickMarcas": "chevrolet\nvolkswagen\nford\nrenault\npeugeot\nfiat"
        }
    },
    "search": {
        "searchboxPlaceholder": "Buscar problema...",
        "errorExplanation": "Hubo un problema realizando la búsqueda :( Reintenta más tarde por favor",
        "popularSearches": "Búsquedas populares"
    },
    "global": {
        "otherBrands": "Otras marcas...",
        "opinautos": "Opinautos",
        "opinautosDomain": "Opinautos.com",
        "writeUs": "Escríbenos haciendo click aquí",
        "writeUsShort": "Escríbenos",
        "welcome": "Bienvenido",
        "commentsAboutTheSite": "Comentarios sobre el sitio?",
        "cancel": "Cancelar",
        "close": "Cerrar",
        "ok": "Aceptar",
        "thanks": "Gracias",
        "distanceUnit": "kms",
        "dateDistance": "hace {{distance}}",
        "saveChanges": "Guardar cambios",
        "loading": "Cargando",
        "otherLanguages": {
            "en": {
                "otherLanguageName": "English",
                "otherLanguageDomainUrl": "https://www.startmycar.com",
                "otherLanguageFlagImage": "/images/banderas/us.png",
                "modelProblemsLinkText": "%s problems in English",
                "problemsUrl": "https://www.startmycar.com/%s/%s/problems"
            },
            "pt": {
                "otherLanguageName": "Português",
                "otherLanguageDomainUrl": "https://www.opinautos.com.br",
                "otherLanguageFlagImage": "/images/banderas/br.png",
                "modelProblemsLinkText": "Problemas do %s em português",
                "problemsUrl": "https://www.opinautos.com.br/%s/%s/defeitos"
            }
        },
        "letUsKnow": "Avisenos aquí",
        "sendDataCorrection": "¿Has encontrado un error o algo para mejorar?",
        "tellUsHere": "Cuéntanos qué corregir aquí...",
        "thankYouVeryMuch": "¡Muchas gracias!",
        "generations": {
            "generationN": "Gen. {{n}}",
            "restyle": {
                "0": "Pre-restyling",
                "1": "1er restyling",
                "2": "2do restyling",
                "3": "3er restyling",
                "4": "4to restyling",
                "5": "5to restyling",
                "6": "6to restyling"
            },
            "other": "Otras versiones"
        }
    },
    "lang": {
        "language": "Idioma",
        "es": "español",
        "en": "inglés",
        "pt": "portugués"
    },
    "titles": {
        "help": "Ayuda - Opinautos",
        "searchModel": "Buscar problemas y fallas %s - Opinautos",
        "searchGlobal": "Buscar problemas y fallas - Opinautos",
        "searchBySymptom": "Síntomas del vehículo - Opinautos",
        "signUp": "Registrarse - Opinautos",
        "logInOrSignUp": "Entrar o registrarse - Opinautos",
        "pickBrand": "Elegir marca - ",
        "pickModel": "Elegir modelo de ",
        "problemsModel": "Problemas y fallas %s %s - Opinautos",
        "problemsModelNew": "%s %s: Problemas y soluciones - Opinautos",
        "problemsModelAdd": "Agregar defecto, problema o falla %s %s - Opinautos",
        "problemsModelTag": "Problemas {tag} {make} {model} - Opinautos",
        "problemsModelTag_oldversion": "Problemas y fallas {tag} {make} {model} - Opinautos",
        "problemsModelTag_problemasNeutralModelo": "Problemas {tag} {make} {model}: Soluciones - Opinautos",
        "problemsModelTag_modeloParte": "{make} {model} {tag}: fallas y soluciones - Opinautos",
        "problemsModelTag_parteModelo": "{tag} {make} {model}: fallas y soluciones - Opinautos",
        "problemsModelTag_problemasParteModelo": "Problemas de {tag} {make} {model}: Soluciones - Opinautos",
        "problemsModelTag_sintomaModelo": "{tag} {make} {model}: causas y soluciones - Opinautos",
        "problemsModelTag_neutralModelo": "{tag} {make} {model} - Opinautos",
        "problemsModelTag_modeloSintoma": "{make} {model} {tag}: causas y soluciones - Opinautos",
        "problemsModelPage": "Problemas y fallas %s %s - Pág %s - Opinautos",
        "problemsModelPageNew": "%s %s: Problemas y soluciones - Pág %s - Opinautos",
        "modelSummary": "Información %s %s - Opinautos",
        "modelComparison": "Comparación %s %s con otros modelos - Opinautos",
        "modelComparisonVs": "Comparación %s %s y %s %s - Opinautos",
        "modelReviews": "Opiniones del {{make}} {{model}} - Opinautos",
        "modelGuides": "Guías útiles {{make}} {{model}}",
        "modelCommunity": "Comunidad del {{make}} {{model}} - Opinautos",
        "myAccount": "Mi cuenta - Opinautos",
        "memberProfile": "Perfil de %s - Opinautos",
        "professionalProfile": "Profesional: %s - Opinautos",
        "membersTopByScore": "Ranking de contribuciones - Opinautos",
        "forgotPassword": "Olvidé mi contraseña - Opinautos",
        "userConfig": "Configurar de mi cuenta - Opinautos",
        "newPassword": "Nueva contraseña - Opinautos",
        "problemsModelEdit": "Editar reporte",
        "problemsModelIndividual": "%s %s %s - Opinautos",
        "problemsCommentEdit": "Editar comentario",
        "modelOwnersManual": "Manual del %s %s - Opinautos",
        "modelOwnersManualYear": "Manual del {{make}} {{model}} ({{year}}) - Opinautos",
        "modelOwnersManualRepair": "Manuales de taller y reparación {{make}} {{model}} - Opinautos",
        "modelOwnersManualUpload": "Cargar manual del propietario %s %s - Opinautos",
        "modelWarningLights": "Luces testigo del %s %s - Opinautos",
        "modelFuseBox": "Diagrama de fusibles {{make}} {{model}} todos los años - Opinautos",
        "modelFuseBoxYear": "Diagrama de fusibles {{make}} {{model}} {{year}} - Opinautos",
        "modelFuseBoxIndividualFuse": "{{fuse}} {{make}} {{model}} {{year}} - Opinautos",
        "userAddVehicle": "Agregar o editar vehículo",
        "modelTrimsNoCountry": "Versiones del {{make}} {{model}}",
        "modelTrims": "Versiones del {{make}} {{model}} en {{country}}"
    },
    "tagging": {
        "catMechanic": "Mecánica",
        "catMechanicSlug": "mecanica",
        "catSecurity": "Seguridad",
        "catSecuritySlug": "seguridad"
    },
    "urls": {
        "compare": "comparar",
        "problems": "defectos",
        "pagePrefix": "pag",
        "addNewProblem": "agregar",
        "add": "agregar",
        "pickBrand": "elegirmarca",
        "pickModel": "elegirmodelo",
        "byDate": "recientes",
        "byScore": "destacados",
        "byNoAnswers": "sin-respuesta",
        "auth": "auth",
        "addlogin": "addlogin",
        "facebook": "facebook",
        "registrationLogin": "registracionlogin",
        "userAlreadyExists": "registracionyaexiste",
        "callback": "callback",
        "myAccount": "micuenta",
        "myCar": "miauto",
        "members": "miembros",
        "professional": "profesionales",
        "topByScore": "ranking",
        "profile": "perfil",
        "logout": "logout",
        "search": "buscar",
        "reviews": "opiniones",
        "signup": "registracion",
        "forgotPassword": "olvidepassword",
        "newPassword": "nuevopassword",
        "userConfig": "configuracion",
        "help": "ayuda",
        "information": "info",
        "ownersManual": "manuales",
        "ownersManualRepair": "taller-reparacion",
        "warningLights": "luces-testigo",
        "fuseBox": "fusibles",
        "upload": "subir",
        "showAll": "todos",
        "guides": "guias",
        "topics": "temas",
        "trims": "versiones",
        "symptomsWizard": "buscar-sintomas",
        "community": "comunidad"
    },
    "menu": {
        "modelOverview": "Información útil",
        "modelOverviewShort": "Información",
        "modelProblems": "Problemas <br>y fallas",
        "modelProblems2": "Problemas y fallas",
        "modelProblemsShort": "Problemas",
        "modelCompare": "Comparaciones",
        "modelCompareShort": "Comparar",
        "modelCommunity": "Comunidad",
        "modelReviews": "Opiniones de dueños",
        "modelReviewsShort": "Opiniones",
        "help": "Ayuda",
        "search": "Buscar",
        "pickModel": "Elegir modelo",
        "infoOwnersManual": "Manual del propietario",
        "infoServiceManual": "Manuales de taller y reparación",
        "infoWarningLights": "Luces Testigo",
        "infoFuseBox": "Diagrama de fusibles",
        "infoGuides": "Guías útiles",
        "infoTrims": "Versiones del {{model}}",
        "changeModel": "Cambiar"
    },
    "breadcrumbs": {
        "home": "Home",
        "newPassword": "Nueva contraseña",
        "forgoPassword": "Olvidé mi contraseña",
        "login": "Ingresar a mi cuenta",
        "pickBrand": "Elegir marca",
        "pickModel": "Elegir modelo",
        "search": "Buscar",
        "shortProblems": "Problemas",
        "longProblems": "Problemas y fallas",
        "compare": "Comparaciones",
        "addProblem": "Compartir un problema",
        "compareTwoModels": "Comparar dos modelos",
        "signUp": "Unirse a Opinautos",
        "myAccount": "Mi cuenta",
        "ownersManual": "Manuales",
        "ownersManualShort": "Manuales",
        "manualsRepairOther": "Taller y reparación",
        "ownersManualUpload": "Subir",
        "warningLights": "Luces testigo",
        "modelOverview": "Información útil",
        "community": "Comunidad",
        "fuseBox": "Fusibles",
        "guides": "Guías",
        "reviews": "Opiniones",
        "trims": "Versiones"
    },
    "validations": {
        "brandInvalid": "La marca ingresada no es válida.",
        "noModel": "Debe seleccionar un modelo",
        "searchTermTooShort": "La busqueda debe tener al menos 2 letras",
        "searchTermTooLong": "La busqueda no puede tener mas de 1024 letras o espacios",
        "invalidModel": "Modelo no valido",
        "userEmail": "El email parece tener un error de tipeo",
        "userCountry": "Debes seleccionar tu país de residencia",
        "usernameTooShort": "Tienes que elegir un nombre de al menos 4 letras",
        "usernameTooLong": "El nombre no puede tener más de 35 letras o espacios",
        "madeInCountryEmpty": "Debes seleccionar un país de fabricación",
        "carYear": "Debes seleccionar un año de fabricación",
        "carKms": "Debes ingresar un número de kms, aunque sea aproximado",
        "carKmsOnlyDigits": "El número de kms no debe tener puntos, comas ni espacios",
        "mpgGeneral": "Los kms por litro es el número de kms que, en promedio, el vehículo hizo con cada litro de combustible",
        "titleTooShort": "El título tiene que ser más informativo",
        "titleTooLong": "El título no puede ser tan largo",
        "contentTooShort": "El contenido tiene que ser completo y descriptivo. Al menos 25 caracteres.",
        "smallContentTooShort": "Escribe al menos 5 caracteres",
        "replyTooShort": "El comentario tiene que ser más completo y descriptivo. Al menos 20 caracteres.",
        "invalidDenunciaId": "Intento de responder inválido.",
        "invalidCommentId": "Intento de responder inválido.",
        "feedbackTooShort": "El contenido tiene que ser más completo",
        "passwordTooShort": "La contraseña es muy corta, tiene que tener al menos 7 letras",
        "passwordDontMatch": "Las contraseñas no coinciden. Vuelve a escribir la contraseña",
        "starsMustRate": "Elija un puntaje de 1 a 5 estrellas",
        "invalidImages": "Las imágenes enviadas no son válidas",
        "invalidFiles": "Las archivos enviados no son válidos",
        "imageUploadInProgress": "Todavía no se han terminado de subir las imágenes, reenvie el formulario cuando terminen",
        "imageUploadFileType": "El formato de la imagen seleccionada no es compatible con el sitio",
        "imageUploadTooManyFiles": "Se pueden subir hasta un máximo de 10 imágenes",
        "imageUploadFileTooLarge": "La imagen que está tratando de subir es demasiado grande (más de 15mb). Debe achicarla primero",
        "imageUploadFailed": "Hubo un problema subiendo las imágenes. Chequee su conexión",
        "pickVehicle": "Debe elegir alguno de sus vehículos",
        "pdfUploadInProgress": "Todavía no se han terminado de subir las archivos, reenvie el formulario cuando terminen",
        "pdfUploadFileType": "El formato del archivo seleccionado no es PDF",
        "pdfUploadTooManyFiles": "Se pueden subir hasta un máximo de 10 archivos",
        "pdfUploadFileTooLarge": "El archivo PDF que está tratando de subir es demasiado grande (tiene más de 50mb, el máximo permitido).",
        "pdfUploadFailed": "Hubo un problema subiendo las archivos. Chequee su conexión",
        "pdfUploadMustUploadSomething": "No seleccionaste ningún archivo",
        "invalidContribution": "Hubo un problema con la contribución"
    },
    "share": {
        "facebook": {
            "locale": "es_LA",
            "pageURL": "https://www.facebook.com/Opinautos-490034741058959",
            "buttonTitle": "Compartir en Facebook",
            "buttonText": "Compartir",
            "feedTitle": "¿Sabes cómo reparar un %s? ¡Entra aquí!",
            "menuLikeText": "Síguenos en Facebook",
            "footerLikeText": "Síguenos en Facebook"
        }
    },
    "legal": {
        "disclaimerProfessionals": "<strong>Opinautos</strong> no tiene ningún tipo de relación con los profesionales o particulares aquí mencionados y declina cualquier responsabilidad respecto al uso de la información proporcionada e interacciones con los mismos. Los datos y contenidos aquí facilitados tienen carácter meramente informativo, su veracidad no está comprobada y fueron proporcionados por usuarios del sitio."
    },
    "countryCodeToName": {
        "af": "Afganistán",
        "ax": "Islas Åland",
        "al": "Albania",
        "de": "Alemania",
        "ad": "Andorra",
        "ao": "Angola",
        "ai": "Anguila",
        "aq": "Antártida",
        "ag": "Antigua y Barbuda",
        "sa": "Arabia Saudita",
        "dz": "Argelia",
        "ar": "Argentina",
        "am": "Armenia",
        "aw": "Aruba",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaiyán",
        "bs": "Bahamas",
        "bd": "Bangladés",
        "bb": "Barbados",
        "bh": "Baréin",
        "be": "Bélgica",
        "bz": "Belice",
        "bj": "Benín",
        "bm": "Bermudas",
        "by": "Belarús",
        "bo": "Bolivia",
        "bq": "Bonaire, San Eustaquio y Saba",
        "ba": "Bosnia y Herzegovina",
        "bw": "Botsuana",
        "br": "Brasil",
        "bn": "Brunéi Darussalam",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "bt": "Bhután",
        "cv": "Cabo Verde",
        "kh": "Camboya",
        "cm": "Camerún",
        "ca": "Canadá",
        "qa": "Qatar",
        "td": "Chad",
        "cl": "Chile",
        "cn": "China",
        "cy": "Chipre",
        "co": "Colombia",
        "km": "Comoras",
        "kp": "Corea del Norte",
        "kr": "Corea del Sur",
        "ci": "Costa de Marfil",
        "cr": "Costa Rica",
        "hr": "Croacia",
        "cu": "Cuba",
        "cw": "Curaçao",
        "dk": "Dinamarca",
        "dm": "Dominica",
        "ec": "Ecuador",
        "eg": "Egipto",
        "sv": "El Salvador",
        "ae": "Emiratos Árabes Unidos",
        "er": "Eritrea",
        "sk": "Eslovaquia",
        "si": "Eslovenia",
        "es": "España",
        "us": "Estados Unidos",
        "ee": "Estonia",
        "et": "Etiopía",
        "eu": "Unión Europea",
        "ph": "Filipinas",
        "fi": "Finlandia",
        "fj": "Fiji",
        "fr": "Francia",
        "ga": "Gabón",
        "gm": "Gambia",
        "ge": "Georgia",
        "gh": "Ghana",
        "gi": "Gibraltar",
        "gd": "Granada",
        "gr": "Grecia",
        "gl": "Groenlandia",
        "gp": "Guadeloupe",
        "gu": "Guam",
        "gt": "Guatemala",
        "gf": "Guayana Francesa",
        "gg": "Guernsey",
        "gn": "Guinea",
        "gw": "Guinea Bissau",
        "gq": "Guinea Ecuatorial",
        "gy": "Guyana",
        "ht": "Haití",
        "hn": "Honduras",
        "hk": "Hong Kong",
        "hu": "Hungría",
        "in": "India",
        "id": "Indonesia",
        "iq": "Irak",
        "ir": "Irán",
        "ie": "Irlanda",
        "bv": "Isla Bouvet",
        "im": "Isla de Man",
        "cx": "Isla de Navidad",
        "is": "Islandia",
        "ky": "Islas Caimán",
        "cc": "Islas Cocos / Keeling",
        "ck": "Islas Cook",
        "fo": "Islas Feroe",
        "gs": "Georgia del Sur y las Islas Sandwich del Sur",
        "hm": "Heard e Islas McDonald",
        "fk": "Islas Malvinas",
        "mp": "Islas Marianas del Norte",
        "mh": "Islas Marshall",
        "pn": "Pitcairn",
        "sb": "Islas Salomón",
        "tc": "Islas Turcas y Caicos",
        "um": "Islas Ultramarinas Menores de los Estados Unidos",
        "vg": "Islas Vírgenes británicas",
        "vi": "Islas Vírgenes de los Estados Unidos",
        "il": "Israel",
        "it": "Italia",
        "jm": "Jamaica",
        "jp": "Japón",
        "je": "Jersey",
        "jo": "Jordania",
        "kz": "Kazajistán",
        "ke": "Kenia",
        "kg": "Kirguistán",
        "ki": "Kiribati",
        "kw": "Kuwait",
        "la": "Laos",
        "ls": "Lesoto",
        "lv": "Letonia",
        "lb": "Líbano",
        "lr": "Liberia",
        "ly": "Libia",
        "li": "Liechtenstein",
        "lt": "Lituania",
        "lu": "Luxemburgo",
        "mo": "Macao",
        "mk": "Macedonia del Norte",
        "mg": "Madagascar",
        "my": "Malasia",
        "mw": "Malawi",
        "mv": "Maldivas",
        "ml": "Malí",
        "mt": "Malta",
        "ma": "Marruecos",
        "mq": "Martinique",
        "mu": "Mauricio",
        "mr": "Mauritania",
        "yt": "Mayotte",
        "mx": "México",
        "fm": "Micronesia",
        "md": "Moldova",
        "mc": "Mónaco",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ms": "Montserrat",
        "mz": "Mozambique",
        "mm": "Myanmar",
        "na": "Namibia",
        "nr": "Nauru",
        "np": "Nepal",
        "ni": "Nicaragua",
        "ne": "Níger",
        "ng": "Nigeria",
        "nu": "Niue",
        "nf": "Norfolk, Isla",
        "no": "Noruega",
        "nc": "Nueva Caledonia",
        "nz": "Nueva Zelandia",
        "om": "Omán",
        "nl": "Holanda",
        "pk": "Pakistán",
        "pw": "Palau",
        "ps": "Palestina, Estado de",
        "pa": "Panamá",
        "pg": "Papua Nueva Guinea",
        "py": "Paraguay",
        "pe": "Perú",
        "pf": "Polinesia Francesa",
        "pl": "Polonia",
        "pt": "Portugal",
        "pr": "Puerto Rico",
        "gb": "Reino Unido",
        "eh": "Sahara Occidental",
        "cf": "República Centroafricana",
        "cz": "República Checa",
        "cg": "El Congo",
        "cd": "República Democrática del Congo",
        "do": "República Dominicana",
        "re": "Reunión",
        "rw": "Ruanda",
        "ro": "Rumania",
        "ru": "Rusia",
        "ws": "Samoa",
        "as": "Samoa Americana",
        "bl": "Saint Barthélemy",
        "kn": "San Cristóbal y Nieves",
        "sm": "San Marino",
        "mf": "Saint Martin (parte francesa)",
        "pm": "San Pedro y Miquelón",
        "vc": "San Vicente y las Granadinas",
        "sh": "Santa Helena, Ascensión y Tristán de Acuña",
        "lc": "Santa Lucía",
        "st": "Santo Tomé y Príncipe",
        "sn": "Senegal",
        "rs": "Serbia",
        "sc": "Seychelles",
        "sl": "Sierra leona",
        "sg": "Singapur",
        "sx": "Sint Maarten (parte neerlandesa)",
        "sy": "República Árabe Siria",
        "so": "Somalia",
        "lk": "Sri Lanka",
        "sz": "Suazilandia",
        "za": "Sudáfrica",
        "sd": "Sudán (el)",
        "ss": "Sudán del Sur",
        "se": "Suecia",
        "ch": "Suiza",
        "sr": "Suriname",
        "sj": "Svalbard y Jan Mayen",
        "th": "Tailandia",
        "tw": "Taiwán",
        "tz": "Tanzania",
        "tj": "Tayikistán",
        "io": "Territorio Británico del Océano Índico",
        "tf": "Tierras Australes Francesas",
        "tl": "Timor-Leste",
        "tg": "Togo",
        "tk": "Tokelau",
        "to": "Tonga",
        "tt": "Trinidad y Tobago",
        "tn": "Túnez",
        "tm": "Turkmenistán",
        "tr": "Turquía",
        "tv": "Tuvalu",
        "ua": "Ucrania",
        "ug": "Uganda",
        "uy": "Uruguay",
        "uz": "Uzbekistán",
        "vu": "Vanuatu",
        "va": "Ciudad del Vaticano",
        "ve": "Venezuela",
        "vn": "Viet Nam",
        "wf": "Wallis y Futuna",
        "ye": "Yemen",
        "dj": "Yibuti",
        "zm": "Zambia",
        "zw": "Zimbabue",
        "other": "otro",
        "global": "Internacional"
    },
    "countryNameToCode": {
        "afganistan": "af",
        "islas aland": "ax",
        "albania": "al",
        "alemania": "de",
        "andorra": "ad",
        "angola": "ao",
        "anguila": "ai",
        "antartida": "aq",
        "antigua y barbuda": "ag",
        "arabia saudita": "sa",
        "argelia": "dz",
        "argentina": "ar",
        "armenia": "am",
        "aruba": "aw",
        "australia": "au",
        "austria": "at",
        "azerbaiyan": "az",
        "bahamas": "bs",
        "banglades": "bd",
        "barbados": "bb",
        "barein": "bh",
        "belgica": "be",
        "belice": "bz",
        "benin": "bj",
        "bermudas": "bm",
        "belarus": "by",
        "bolivia": "bo",
        "bonaire, san eustaquio y saba": "bq",
        "bosnia y herzegovina": "ba",
        "botsuana": "bw",
        "brasil": "br",
        "brunei darussalam": "bn",
        "bulgaria": "bg",
        "burkina faso": "bf",
        "burundi": "bi",
        "bhutan": "bt",
        "cabo verde": "cv",
        "camboya": "kh",
        "camerun": "cm",
        "canada": "ca",
        "qatar": "qa",
        "chad": "td",
        "chile": "cl",
        "china": "cn",
        "chipre": "cy",
        "colombia": "co",
        "comoras": "km",
        "corea del norte": "kp",
        "corea del sur": "kr",
        "costa de marfil": "ci",
        "costa rica": "cr",
        "croacia": "hr",
        "cuba": "cu",
        "curacao": "cw",
        "dinamarca": "dk",
        "dominica": "dm",
        "ecuador": "ec",
        "egipto": "eg",
        "el salvador": "sv",
        "emiratos arabes unidos": "ae",
        "eritrea": "er",
        "eslovaquia": "sk",
        "eslovenia": "si",
        "espana": "es",
        "estados unidos": "us",
        "estonia": "ee",
        "etiopia": "et",
        "filipinas": "ph",
        "finlandia": "fi",
        "fiji": "fj",
        "francia": "fr",
        "gabon": "ga",
        "gambia": "gm",
        "georgia": "ge",
        "ghana": "gh",
        "gibraltar": "gi",
        "granada": "gd",
        "grecia": "gr",
        "groenlandia": "gl",
        "guadeloupe": "gp",
        "guam": "gu",
        "guatemala": "gt",
        "guayana francesa": "gf",
        "guernsey": "gg",
        "guinea": "gn",
        "guinea bissau": "gw",
        "guinea ecuatorial": "gq",
        "guyana": "gy",
        "haiti": "ht",
        "honduras": "hn",
        "hong kong": "hk",
        "hungria": "hu",
        "india": "in",
        "indonesia": "id",
        "irak": "iq",
        "iran": "ir",
        "irlanda": "ie",
        "isla bouvet": "bv",
        "isla de man": "im",
        "isla de navidad": "cx",
        "islandia": "is",
        "islas caiman": "ky",
        "islas cocos / keeling": "cc",
        "islas cook": "ck",
        "islas feroe": "fo",
        "georgia del sur y las islas sandwich del sur": "gs",
        "heard e islas mcdonald": "hm",
        "islas malvinas": "fk",
        "islas marianas del norte": "mp",
        "islas marshall": "mh",
        "pitcairn": "pn",
        "islas salomon": "sb",
        "islas turcas y caicos": "tc",
        "islas ultramarinas menores de los estados unidos": "um",
        "islas virgenes britanicas": "vg",
        "islas virgenes de los estados unidos": "vi",
        "israel": "il",
        "italia": "it",
        "jamaica": "jm",
        "japon": "jp",
        "jersey": "je",
        "jordania": "jo",
        "kazajistan": "kz",
        "kenia": "ke",
        "kirguistan": "kg",
        "kiribati": "ki",
        "kuwait": "kw",
        "laos": "la",
        "lesoto": "ls",
        "letonia": "lv",
        "libano": "lb",
        "liberia": "lr",
        "libia": "ly",
        "liechtenstein": "li",
        "lituania": "lt",
        "luxemburgo": "lu",
        "macao": "mo",
        "macedonia del norte": "mk",
        "madagascar": "mg",
        "malasia": "my",
        "malawi": "mw",
        "maldivas": "mv",
        "mali": "ml",
        "malta": "mt",
        "marruecos": "ma",
        "martinique": "mq",
        "mauricio": "mu",
        "mauritania": "mr",
        "mayotte": "yt",
        "mexico": "mx",
        "micronesia": "fm",
        "moldova": "md",
        "monaco": "mc",
        "mongolia": "mn",
        "montenegro": "me",
        "montserrat": "ms",
        "mozambique": "mz",
        "myanmar": "mm",
        "namibia": "na",
        "nauru": "nr",
        "nepal": "np",
        "nicaragua": "ni",
        "niger": "ne",
        "nigeria": "ng",
        "niue": "nu",
        "norfolk, isla": "nf",
        "noruega": "no",
        "nueva caledonia": "nc",
        "nueva zelandia": "nz",
        "oman": "om",
        "holanda": "nl",
        "pakistan": "pk",
        "palau": "pw",
        "palestina, estado de": "ps",
        "panama": "pa",
        "papua nueva guinea": "pg",
        "paraguay": "py",
        "peru": "pe",
        "polinesia francesa": "pf",
        "polonia": "pl",
        "portugal": "pt",
        "puerto rico": "pr",
        "reino unido": "gb",
        "sahara occidental": "eh",
        "republica centroafricana": "cf",
        "republica checa": "cz",
        "el congo": "cg",
        "republica democratica del congo": "cd",
        "republica dominicana": "do",
        "reunion": "re",
        "ruanda": "rw",
        "rumania": "ro",
        "rusia": "ru",
        "samoa": "ws",
        "samoa americana": "as",
        "saint barthelemy": "bl",
        "san cristobal y nieves": "kn",
        "san marino": "sm",
        "saint martin (parte francesa)": "mf",
        "san pedro y miquelon": "pm",
        "san vicente y las granadinas": "vc",
        "santa helena, ascension y tristan de acuna": "sh",
        "santa lucia": "lc",
        "santo tome y principe": "st",
        "senegal": "sn",
        "serbia": "rs",
        "seychelles": "sc",
        "sierra leona": "sl",
        "singapur": "sg",
        "sint maarten (parte neerlandesa)": "sx",
        "republica arabe siria": "sy",
        "somalia": "so",
        "sri lanka": "lk",
        "suazilandia": "sz",
        "sudafrica": "za",
        "sudan (el)": "sd",
        "sudan del sur": "ss",
        "suecia": "se",
        "suiza": "ch",
        "suriname": "sr",
        "svalbard y jan mayen": "sj",
        "tailandia": "th",
        "taiwan": "tw",
        "tanzania": "tz",
        "tayikistan": "tj",
        "territorio britanico del oceano indico": "io",
        "tierras australes francesas": "tf",
        "timor-leste": "tl",
        "togo": "tg",
        "tokelau": "tk",
        "tonga": "to",
        "trinidad y tobago": "tt",
        "tunez": "tn",
        "turkmenistan": "tm",
        "turquia": "tr",
        "tuvalu": "tv",
        "ucrania": "ua",
        "uganda": "ug",
        "uruguay": "uy",
        "uzbekistan": "uz",
        "vanuatu": "vu",
        "ciudad del vaticano": "va",
        "venezuela": "ve",
        "viet nam": "vn",
        "wallis y futuna": "wf",
        "yemen": "ye",
        "yibuti": "dj",
        "zambia": "zm",
        "zimbabue": "zw",
        "otro": "other",
        "other": "other",
        "internacional": "global"
    }
};})()