import React, { Component } from 'react';
import _ from 'lodash';

import LegosManager from '../LegosManager';
import { SearchFilterButton } from '../../components/common/SearchFilterButton';
import { TypeTag } from '../../components/lego/TypeTag';
import { IconButton } from '../../components/common/IconButton';
import ModalCreateTasksFromLegos from '../../components/lego/ModalCreateTasksFromLegos';
import FuseboxComparison from '../../components/lego/fusebox-editor/FuseboxComparison';
import { LegoFiltersBar } from '../../components/common/LegoFiltersBar';
import { LegoPreview } from '../../components/lego/LegoPreview';
import ImageClusterPreview from '../../components/images/ImageClusterPreview';
import MultiButtonSwitch from '../../components/common/editors/MultiButtonSwitch';

function FuseboxTypeSelector({value, onChange}) {
  const allTypes = ['fusebox', 'fuseboxTemplate'];

  if(!value)
    value = {$in: []};

  let {$in: types} = value;

  if(!_.difference(allTypes, types).length) {
    types = [];
  }

  let onChangeHandler = (type) => {
    if(_.includes(types, type)) {
      types = _.without(types, type);
    } else {
      types = [... types, type];
    }
    onChange({$in: types.length ? types : allTypes});
  }

  return <span>
    {_.map(allTypes, t =>
      <SearchFilterButton key={t} onChange={() => onChangeHandler(t)} value={_.includes(types, t)}>
        <TypeTag key={t} type={t}/>
      </SearchFilterButton>)
    }
  </span>;
}

const PREVIEW_CLUSTER = 'Cluster';
const PREVIEW_DIAGRAM = 'Diagram';
const PREVIEW_FUSEBOX = 'Fusebox';


export default class FuseboxManager extends LegosManager {
  constructor(... args) {
    super(... args);
    this.state.facetFilters = (this.state.facetFilters || {})
    this.state.facetFilters.type = {$in: ['fusebox', 'fuseboxTemplate']}

    // Skip data.fuses to transfer 10X less data
    this.state.extraQueryOptions = {$select: {"data.fuses": {$slice: 1}, "data.boxDescription": false}}

    this.state.previewType = PREVIEW_DIAGRAM;
    // this.state.previewType = PREVIEW_CLUSTER;

    this.rankService = this.service('/services/legos/fusebox-rank');

    // setTimeout(() => this.openCompareModal("61ad203f963f62000474813b", "61ad225e963f620004748146"),100);
  }

  getRankQuery() {
    // locales and context are not needed, as they are queried in the server
    const legoData = this.state.objects.map(({_id, locales, context}) => ({_id}));
    return {query: {legos: legoData, rankCriteria: this.state.rankCriteria}};
  }

  buildObjectQuery() {
    let originalQuery = super.buildObjectQuery();

    if(_.keys(originalQuery).length === 2) {
      originalQuery.$limit = 20;
    }
    return originalQuery;
  }

  getColumnsDefinition(objects) {
    // TODO: Coupled with LegosManager columnDefinition. Might break
    let [type, sem, ... rest] = super.getColumnsDefinition(objects);

    const pre = rest[rest.length-1];
    pre.content = <span>
      Preview: <MultiButtonSwitch value={this.state.previewType} onChange={(v) => this.setState({previewType: v})} options={[PREVIEW_DIAGRAM, PREVIEW_CLUSTER]}/>
    </span>;

    return [type, ... rest];
  }

  getSelectionButtons(selection) {
    let compareBtn = null;
    if(selection.length === 2) {
      let [a,b] = selection;
      compareBtn = <IconButton icon={'compare'} title="Compare fuseboxes" onClick={() => this.openCompareModal(a._id, b._id)}/>
    }

    return <>
      { super.getSelectionButtons(selection) }
      { compareBtn }
      <IconButton icon={'add_task'} title={'Create tasks for selected fuseboxes'} onClick={() => this.openCreateTasksModal(selection)}/>
    </>
  }

  openCompareModal(fuseboxA, fuseboxB) {
    this.openModal(
      <FuseboxComparison
        fuseboxIdA={fuseboxA}
        fuseboxIdB={fuseboxB}
        onMergeFuseboxLegos={(idDeleted, idMergedInto) => {
          if(confirm(`Fusebox with ID ${idDeleted} will be deleted.\n\nIts context/sources will be merged with fusebox ${idMergedInto}.\n\nARE YOU REALLY SURE?`)) {
            this.runAsync(async () => {
              let toDelete = await this.views.get(idDeleted);
              let toEdit = await this.views.get(idMergedInto);

              toEdit.context = _.sortBy([... toEdit.context, ... toDelete.context], 'modelId', 'year');
              toEdit.source = [... toEdit.source, ... toDelete.source];
              toEdit.notes = [... toEdit.notes, {
                content: `Lego ID ${idDeleted} was merged into this and deleted.`,
                userId: this.getLoggedUserSignature().id,
                createdAt: new Date()
              }
              ];

              await this.edit(toEdit, toEdit);
              await this.delete(toDelete, true);

              console.log(`Merged ${idDeleted} into ${idMergedInto}. Resulting lego: `, toEdit);

              this.selectNone();

              this.closeModal();
            })
          }
        }}
      />,
      { fullScreen: true, title: 'Compare fusebox legos' });
  }

  openCreateTasksModal(selection) {
    this.modalActionsBus.emit('open', <ModalCreateTasksFromLegos legos={selection} onCancel={() => this.modalActionsBus.emit('close')}/>);
  }

  filterByFuseDescription(desc = '') {
    let regex = prompt('Enter a regex to filter in the fuse description', desc);
    if(regex) {
      this.onFiltersChange({...this.state.facetFilters, 'data.fuses.description': {$regex: regex}});
    }
  }

  getFacetFiltersComponent() {
    let facetFilters = this.state.facetFilters || {};

    const fields = ['locales', 'context', 'state', 'labels', '_id'];
    const onTypeChange = (f) => this.onFiltersChange({... facetFilters, type: f});

    let desc = facetFilters['data.fuses.description'];

    return <div>
      <span className={''}>
        <FuseboxTypeSelector value={facetFilters.type} onChange={onTypeChange}/>
      </span>

      <span className={'mr-3'}>
        <IconButton icon={'find_in_page'} level={desc ? 'primary' : 'secondary'} title="Search inside fuse description with a regex" onClick={() => this.filterByFuseDescription(desc?.$regex)}/>
        {desc ? <span className={'badge badge-primary badge-outline'}>/{desc.$regex}/</span> : null}
        {desc ? <IconButton icon={'clear'} level={'danger'} onClick={() => this.onFiltersChange(_.omit(facetFilters, 'data.fuses.description'))}/> : null}
      </span>

      <LegoFiltersBar fields={fields} onChange={this.onFiltersChange.bind(this)} filter={facetFilters}/>

    </div>
  }

  onAddNewClick(overrides) {
    // Override defaults for new Button
    super.onAddNewClick({
      type: 'fusebox',
      intentions: ["spec"],
      semantics: ["DIAGRAMA_DE_FUSIBLES"],
      data: [{fuses: []}],
      state: 'inprogress'
    })
  }

  getObjectColumns(l, definition) {
    // Ignore intention and sem columns, as all fusebox have the same values there
    let [type, sem, ... rest] = super.getObjectColumns(l, definition);

    let preview = rest.slice(-1)[0];
    if(this.state.previewType === PREVIEW_CLUSTER) {
      let clusterId = l.data?.[0]?.imageClusters;
      if(clusterId?.length) {
        preview = <td key={'pre'} className={'data-column'} onClick={() => this.openEdit(l)}>
          <ImageClusterPreview clusterId={clusterId} maxImages={5}/>
        </td>
      }
    } else if(this.state.previewType === PREVIEW_DIAGRAM) {
      preview = <td key={'pre'} className={'data-column'} onClick={() => this.openEdit(l)}>
        <LegoPreview lego={l} textFilter={this.state.textFilter}/>
      </td>
    }

    return [type, ... rest.slice(0,-1), preview];
  }
}
