import React, { Component, useState } from 'react';
import EventEmitter from 'events';

import LightSelect from '../LightSelect';
import ModalManager from '../../ModalManager';
import MultilineTextEditor from '../../common/editors/MultilineTextEditor';
import { IconButton } from '../../common/IconButton';
import FuseboxTableImgProcessor from './FuseboxTableImgProcessor';
import _ from 'lodash';
import { extendFusesWithTemplate } from './fusebox-utils.mjs';
import { IconButtonToggle } from '../../common/IconButtonToggle';
import ClipboardListener, { copyText } from '../../common/clipboard/ClipboardListener';
import { FUSE_TYPE_FORMATS } from '../../common/FuseboxData.mjs';
import LegoAdminPageContext from '../../../pages/legoAdminPageContext';
import SingleTextEditor from '../../common/editors/SingleTextEditor';

function extendFuse(updatedFuse, existing) {
  if (updatedFuse) {
    return { ...existing, ...updatedFuse, kind: { ...existing.kind, ..._.pickBy(updatedFuse.kind) } };
  } else {
    return existing;
  }
}

const RELAY_COLOR_VARIATIONS = {
  full: ['black', 'gray', 'lightgray', 'cream', 'cyan', 'lightblue', 'indigo', 'purple', 'pink', 'green', 'lightgreen', 'yellow', 'orange', 'red', 'brown'],
  half: ['black', 'gray', 'lightgray', 'cream', 'cyan', 'blue', 'purple', 'darkred', 'brown', 'orange', 'yellow', 'green']
};
RELAY_COLOR_VARIATIONS.ultra = RELAY_COLOR_VARIATIONS.full;
RELAY_COLOR_VARIATIONS[''] = RELAY_COLOR_VARIATIONS.full;


function RelayVariationSelector({ value, fuse, onChange }) {
  let variations = RELAY_COLOR_VARIATIONS[fuse.kind.format] || [];
  return <div className={'fusebox-diagram'} style={{ maxWidth: '600px' }}>
    { variations.map(v => <span key={v} onClick={() => onChange(v)}
                                className={`align-middle d-inline-block fuse fuse-relay  fuse-vertical relay-${fuse.kind.format} fuse-var-${v} ${v === value ? 'highlighted' : ''}`}
                                style={{ width: '100px', height: '100px', position: 'relative'}}>
      <span className={'inner'}>{v}</span>
      </span>)}
    {value ? <span className={'btn btn-primary m-2'} onClick={() => onChange(undefined)}>Remove variation</span> : null }
  </div>
}

function FootnoteEditor({footnotes, onSave}) {
  let [noteMap, setNoteMap] = useState(_.keyBy(footnotes || [], f => f));

  let noChanges = _.every(noteMap, (a, b) => a == b);

  return <div className={'px-3 pt-3'}>
    <div className={'text-center'}>
      <table className={'table'}>
        <tbody>
        {_.map(noteMap || [], (to, from) => <tr key={from}>
          <td className={'text-right align-middle small'} width={'50%'}>{from}</td>
          <td>
            <SingleTextEditor small className={(from === to) ? '' : 'bg-light-warn'} value={to || ''}
                              onChange={txt => setNoteMap({ ...noteMap, [from]: txt })}/>
          </td>
        </tr>)}
        </tbody>
      </table>
    </div>

    <div className={'mt-3 text-center'}>
      <button className={'btn btn-primary'} disabled={noChanges} onClick={() => onSave(noteMap)}>Replace footnotes!</button>
    </div>
  </div>;
}

export function FuseDescription({ description }) {
  // Split description in conditional parts between brackets: 'AAA [X]. BBB. CCC [X]' -> 'AAA ', '[X]', '. BBB. CCC ', '[X]'
  let descriptionParts = (description || '').replaceAll(/(\[[^\]\[]+\])/g, '%%$1%%').split('%%');

  return <>
    {descriptionParts.map((part, i) => {
      return <span key={i + '__' + part}>{part.startsWith('[') ? <span className={'monospace bg-light-info'}>{part}</span> : part}</span>;
    })}
  </>;
}

class FuseboxTableEditor extends Component {
  constructor(props) {
    super(props);

    this.modalActionsBus = new EventEmitter();
    this.state = {
      editingDescriptionIndex: null,
      clipboard: null,
      textFilter: ''
    };
    // setTimeout(() => this.importFusesFromTableImage(), 200);
  }

  onFuseFieldEdited(fuse, fieldPath, newValue) {
    // Needed to find selected fuses
    const extendedFuses = this.extendedFuses();

    let fuseIndex = _.findIndex(extendedFuses, f => {
      if (f.mergedFromFuse) {
        return f.mergedFromFuse === fuse.mergedFromFuse;
      } else {
        return f.mergedFromTemplate === fuse.mergedFromTemplate;
      }
    });

    let selectedIndexes = this.props.selectedFuses || [fuseIndex];

    // If the edited fuse was actually a merge between a template, for the edit only touch the original fuse
    if (fuse.mergedFromFuse) {
      fuse = fuse.mergedFromFuse;

      // When the fuse has undefined notUsed but inherits a "false" from template, the tri-state loops from
      // false to undefined, which then inherits false again creating a loop. Break by jumping to true
      if(fieldPath === 'kind.notUsed' && newValue === undefined && fuse.kind?.notUsed === newValue) {
        newValue =  true;
      }
    }

    // If edited fuse is not part of selection, change selection to that fuse
    if (!_.includes(selectedIndexes, fuseIndex)) {
      selectedIndexes = [fuseIndex];
    }

    for (const selection of selectedIndexes) {
      let selFuse = extendedFuses[selection];

      if (selFuse.mergedFromFuse) {
        selFuse = selFuse.mergedFromFuse;
      } else if (selFuse.fromTemplate) {
        // Add a new fuse with the changed prop (that overrides the template's fuse)
        selFuse = { id: selFuse.id, kind: {} };
        this.props.fuses.push(selFuse);
        this.props.onFuseSelection([selFuse])
      }

      let finalFuse = selFuse?.mergedFromFuse || selFuse;
      // Special case to be able to batch edit multiple fuses ids keeping numeration
      if(fuse.id !== finalFuse.id && fieldPath === 'id' && newValue.match(/\d+/) && finalFuse.id.toString().match(/\d+/)) {
        let numberStableNewVal = newValue.replace(/\d+/, finalFuse.id.match(/\d+/)[0]);
        _.set(finalFuse, fieldPath, numberStableNewVal);
      } else {
        _.set(finalFuse, fieldPath, newValue);
      }
    }

    this.props.onChange(this.props.fuses, undefined, fieldPath === 'description');
  }

  selectFuses(i, event, fusesPairs) {
    let sel = this.props.selectedFuses;

    if (event?.shiftKey && sel && sel.length) {
      if (!_.includes(sel, i) && !this.state.textFilter) {
        if (sel[0] > i) {
          this.props.onFuseSelection(_.sortBy(_.range(i, sel[0]).concat(sel)));
        } else if (sel[sel.length - 1] < i) {
          this.props.onFuseSelection(_.sortBy(sel.concat(_.range(sel[sel.length - 1] + 1, i + 1))));
        }
      }
    } else if (event?.ctrlKey && sel && sel.length) {
      if (_.includes(sel, i)) {
        this.props.onFuseSelection(_.without(sel, i));
      } else {
        this.props.onFuseSelection(_.sortBy([...sel, i]));
      }
    } else {
      this.props.onFuseSelection([i]);
    }
    event?.stopPropagation();
  }

  toggleNotUsed(fuse, value) {
    let shouldSetNotUsed = (value !== undefined ? value : !fuse.kind.notUsed);

    if (shouldSetNotUsed) {
      this.onFuseFieldEdited(fuse, 'kind.notUsed', true);
    } else {
      // Backwards compatibility: before, empty amp was used to indicate notUsed
      // Now, if no amp but isabled notUsed, force notUsed un false, it indicates fuse
      // is used but amp is unknown
      if (fuse.kind.amp) {
        this.onFuseFieldEdited(fuse, 'kind.notUsed', undefined);
      } else {
        this.onFuseFieldEdited(fuse, 'kind.notUsed', false);
      }
    }

    this.props.onChange(this.props.fuses);
  }

  scrollToFuse(id) {
    let $row = $(`.fuse-table-editor tr.fuse-id-${id.replaceAll(/\W/g, '')}`);
    if($row.length) {
      $row[0].scrollIntoView({block: 'center'});
    }
  }

  focusFuseAmpInput(fuseId) {
    // Hack to make autofocus sort of work
    let $row = $(`.fuse-table-editor tr.fuse-id-${fuseId.toString().replaceAll(/\W/g, '')} .ampInput`);
    if($row.length) {
      $row[0].focus();
    }
  }

  getFuseRow(fuse, i, fusesPairs) {
    const { id, unmappedId, description, extendedDescription, relatedEntities, kind, layout, fromTemplate, mergedFromTemplate, mergedFromFuse } = fuse;

    let rowClasses = [`fuse-id-`+id.toString().replaceAll(/\W/g, '')];
    if (fromTemplate) {
      rowClasses.push('bg-light-secondary');
    } else if (!layout?.width) {
      rowClasses.push('bg-light-danger');
    } else if (mergedFromTemplate) {
      rowClasses.push('bg-light-partial-success');
    } else {
      rowClasses.push('bg-light-success');
    }

    const isSelected = _.includes(this.props.selectedFuses, i);
    if (isSelected) {
      rowClasses.push('row-selected');
    }

    const onAmpChange = e => {
      const amp = e.target.value;
      this.onFuseFieldEdited(fuse, 'kind.amp', amp);

      // If it is a fuse, auto set 'notUsed' if amp is empty
      // TODO: Broken with templates, fix
      if (fuse.kind.type === 'fuse') {
        if (fuse.kind.notUsed === true && amp) {
          // If it was not used, remove the mark
          this.toggleNotUsed(fuse, false);
        } else if (!amp && fuse.kind.notUsed !== true) {
          // If amp removed, by default assume it is used but we don't know.
          // kind.notUsed == undefined with no amp is taken as empty. So set explicitly notUsed = false
          this.toggleNotUsed(fuse, false);
        }
      }
    };

    const onEditVariation = () => {
      let current = fuse.kind.variation || '';
      this.context.page.openModal(<RelayVariationSelector value={current}
                                                          onChange={(v) => {
                                                            this.context.page.closeModal();
                                                            this.onFuseFieldEdited(fuse, 'kind.variation', v);
                                                          }}
                                                          fuse={fuse}/>, {
        title: 'Select relay variation: ' + current, minSize: false
      });

      // const variations = RELAY_COLOR_VARIATIONS[fuse.kind.format] || [];
      // const selectedVar = prompt('Enter a variation of: '+variations.join(' - ') , fuse.kind.variation || '');
      // if(selectedVar !== null) {
      //   if(!variations.includes(selectedVar)) {
      //     alert(`Variation ${selectedVar} not found in variations list: ${variations.join(' - ')}. Double check it`);
      //   }
      //   this.onFuseFieldEdited(fuse, 'kind.variation', selectedVar || undefined);
      // }
    };

    let deleteResetBtn;
    if (mergedFromFuse) {
      const remove = () => this.removeFuses([fuse]);
      const removeIcon = mergedFromTemplate ? 'restart_alt' : 'delete_forever';
      deleteResetBtn = <IconButton onClick={remove} icon={removeIcon} level={'danger'} className={'p-0'}/>;
    }

    const renameMap = this.props.templateRenameMap;

    const templateDiffBackground = (field) => {
      // Mark duplicate ids
      const remappedId = f => renameMap ? renameMap[f.id] || f.id: f.id;

      if (field === 'id') {
        return _.filter(this.props.fuses, f => remappedId(f) === id).length > 1 ? 'bg-warning' : '';
      }

      if (mergedFromTemplate) {
        const tempVal = _.get(mergedFromTemplate, field);
        const newVal = _.get(mergedFromFuse, field);

        const notDefined = newVal === undefined || (_.includes(['description', 'kind.format', 'kind.type'], field) && !newVal);


        // If Description or format in template is "", treat it as if it was not there
        if (!notDefined && tempVal !== undefined) {
          return tempVal === newVal ? 'bg-light-success' : 'bg-light-danger';
        } else if (!notDefined) {
          return 'bg-light-success';
        }
      }
      return '';
    };

    const remapped = unmappedId !== undefined;

    // The logic for the rowKey field is pretty delicate, because the row key has to be stable while the editor is
    // typing, but at the same time it needs to be deduced from this.props.fuses. When a user edits the table, it
    // edits either a this.props.fuses fuse or creates a new one (from a template fuse that had no override). So, the
    // trick is that when the user clicks a row of a non-overriden (gray) row, we change the row key to the future
    // index of the fuse that would be created if an edition is made. That way, the key of the edited row has no jumps
    // (the only detail is that gray rows will require to be clicked twice to be edited, as focus is lost when changing
    // the row key)
    const originalFuseIndex = this.props.fuses.indexOf(mergedFromFuse);
    const nextFuseIndex = this.props.fuses.length;
    const rowKey = mergedFromFuse ? originalFuseIndex : (isSelected && this.props.selectedFuses.length === 1 ? nextFuseIndex : 'u'+i);

    const onRenameTemplateFuse = e => {
      this.props.onRenameMapChange({ ...renameMap, [unmappedId || id]: e.target.value });
      this.props.onFuseSelection([])
    };

    const onAmpInputKeyDown = (e) => {
      // Convenience to be able to edit amperages with keyboard, jumping up and down

      let jumpToFuseIndex = fusesPairs.findIndex(p => p[0] === fuse);
      if (e.key === 'ArrowDown') {
        jumpToFuseIndex += 1;
      } else if (e.key === 'ArrowUp') {
        jumpToFuseIndex -= 1;
      } else {
        return;
      }

      if (jumpToFuseIndex >= 0 && jumpToFuseIndex < fusesPairs.length) {
        this.focusFuseAmpInput(fusesPairs[jumpToFuseIndex]?.[0]?.id);
        this.selectFuses(fusesPairs[jumpToFuseIndex][1], null, fusesPairs);
      }
    }

    return <tr key={rowKey} className={rowClasses.join(' ')} onMouseDown={(e) => (!isSelected) ? this.selectFuses(i, e, fusesPairs) : null}>
      <td onMouseDown={e => this.selectFuses(i, e, fusesPairs)}  style={{width: '20px'}}>
        {
          layout?.width
            ?
            <i className={`material-icons align-middle text-success`}>location_on</i>
            :
            <i className={`material-icons align-middle text-danger `}>location_off</i>
        }
      </td>

      <td className={templateDiffBackground('id')} style={{minWidth: '80px'}}>
       <input type={'text'} className={'form-control-plaintext form-control-sm p-0 '+(remapped ? 'text-info' : '')}
               value={remapped ? unmappedId : id}
               onChange={e => this.onFuseFieldEdited(fuse, 'id', e.target.value)}/>
      </td>

      {
        renameMap ? <td>
          <input type={'text'} className={'zoom-75 font-italic text-secondary form-control-plaintext p-0'} value={remapped ? id : ''}
                 onChange={onRenameTemplateFuse}/>
        </td> : null
      }

      <td className={templateDiffBackground('kind.type')}  style={{width: '70px'}}>
        <div className={'zoom-75'}>
          <LightSelect
            className={'form-control form-control-sm form-control-plaintext'}
            onChange={type => {
              this.onFuseFieldEdited(fuse, 'kind.type', type);
              this.onFuseFieldEdited(fuse, 'kind.format', '');
            }}
            options={_.keys(FUSE_TYPE_FORMATS).concat('')}
            placeholder="Pick a type..."
            value={kind.type || ''}
          />
        </div>
      </td>

      <td className={templateDiffBackground('kind.format')}  style={{width: '100px'}}>
        <div className={'zoom-75'}>
          {FUSE_TYPE_FORMATS[kind.type] ? <LightSelect
            className={'form-control form-control-sm form-control-plaintext'}
            onChange={format => this.onFuseFieldEdited(fuse, 'kind.format', format)}
            options={FUSE_TYPE_FORMATS[kind.type] || []}
            placeholder="Pick a format..."
            value={kind.format || ''}
          /> : (kind.format ? <span className={'text-danger'}>{kind.format}</span> : null)}
        </div>
      </td>

      {kind.type !== 'relay'
        ?
        <td className={templateDiffBackground('kind.amp')} style={{width: '60px'}}>
        <input type={'text'} className={'ampInput form-control-plaintext p-0 pr-1 text-right'}
               onKeyDown={e => onAmpInputKeyDown(e)} value={kind.amp || ''}
               onChange={onAmpChange}/>
      </td>
        :
      <td className={templateDiffBackground('kind.variation')} style={{width: '60px'}}>
        <IconButton icon={'palette'} level={kind.variation ? 'primary' : 'secondary'} onClick={onEditVariation} className={''}/>
        { kind.variation ? <span className="d-inline-block align-middle border border-dark rounded" style={{backgroundColor: kind.variation, width: '20px', height: '20px'}}/> : null }
      </td>
      }

      <td className={templateDiffBackground('kind.notUsed')}  style={{width: '50px'}}>
        {
          (kind.type !== 'fuse' || !kind.amp) || kind.notUsed
            ?
            <IconButtonToggle icon={kind.notUsed ? 'check_box' : (kind.notUsed === false ? 'check_box_outline_blank' : 'indeterminate_check_box')}
                              value={kind.notUsed}
                              triState={true}
                              onChange={(val) => this.onFuseFieldEdited(fuse, 'kind.notUsed', val)}/> : null
        }
      </td>

      <td className={templateDiffBackground('description')}>
        {
          this.state.editingDescriptionFuse === fuse.id ?
            <MultilineTextEditor
              type={'text'}
              className={'form-control p-0'}
              value={description || ''}
              onChange={t => this.onFuseFieldEdited(fuse, 'description', t)}/>
            :
            <div onClick={() => this.setState({ editingDescriptionFuse: fuse.id })}>
              <FuseDescription description={description || '...'}/>
            </div>
        }

        {
          (extendedDescription || this.props.useAcronyms) ?
            <div className={'text-primary '+(description && this.props.useAcronyms && !extendedDescription ? 'bg-light-danger' : '')} style={{ wordBreak: 'break-all' }}>
            {extendedDescription || '-'}
          </div>
            :
            null
        }

        <div className={'small monospace text-secondary'} style={{ wordBreak: 'break-all' }}>
          {(relatedEntities || []).join(' - ')}
        </div>
      </td>


      <td  style={{width: '36px'}}>
        {deleteResetBtn}
      </td>
    </tr>;
  }

  addFuse() {
    let lastEmptyNumber = 0;
    if (this.props.fuses?.length) {
      lastEmptyNumber = parseInt(_.map(this.props.fuses, f => (f.id || '').match(/^Empty(\d+)/)?.[1] || 0).sort().
                                   slice(-1)[0] || '0');
    }

    const newFuse = {
      id: 'Empty' + (lastEmptyNumber + 1),
      kind: { type: 'fuse', format: 'mini', notUsed: true },
    };

    this.props.onChange([...(this.props.fuses || []), newFuse]);
  }

  removeFuses(fuses) {
    let remainingFuses = _.differenceBy(this.props.fuses, fuses, f => f.mergedFromFuse || f);

    this.props.onChange(remainingFuses);

    const renameMap = this.props.templateRenameMap;
    if(renameMap) {
      // Remove from the map any fuse id that was deleted and does not have duplicates in the table
      this.props.onRenameMapChange(_.omit(renameMap, _.difference(_.map(fuses, f => (f.mergedFromFuse || f).id), _.map(remainingFuses, 'id'))));
    }

    if (fuses.length > 1) {
      this.props.onFuseSelection([]);
    }
  }

  importFusesAccordingToOptions(fuses, options) {
    const templateById = _.keyBy(this.props.templateFuses, 'id');
    let extendedFuses = this.props.fuses || [];

    // Don't override template type/format with the same value the template has
    _.each(fuses, fuse => {
      let fuseTemplate = templateById[(this.props.templateRenameMap || {})[fuse.id] || fuse.id];
      if(fuseTemplate && fuseTemplate.kind.type === fuse.kind.type) {
        delete fuse.kind.type;
      }
      if(fuseTemplate && fuseTemplate.kind.format === fuse.kind.format) {
        delete fuse.kind.format;
      }
    })

    if(options.onlyAmps) {
      let byId = _.keyBy(extendedFuses, 'id');
      _.each(fuses, ({id, kind}) => byId[id] && kind?.amp && _.set(byId[id], 'kind.amp', kind.amp));
    } else if(options.replace) {
      let byId = _.keyBy(fuses, 'id');
      // For any fuse on the updated list, update the new/changed properties
      extendedFuses = _.uniqBy(_.union(_.map(extendedFuses, (f) => extendFuse(byId[f.id], f)), fuses), 'id');
    } else {
      _.each(fuses, fuse => extendedFuses.push({ ...fuse }));
    }

    return extendedFuses;
  }

  importFusesFromTableImage(existingImages) {
    let onImport = (fuses, img, options = {}) => {
      let extendedFuses = this.importFusesAccordingToOptions(fuses, options);
      this.props.onChange(extendedFuses, !existingImages && img);
      this.modalActionsBus.emit('close');
    };

    this.modalActionsBus.emit('open', <FuseboxTableImgProcessor existingImages={existingImages}
                                                                fuses={this.extendedFuses()}
                                                                contextId={this.props.contextId}
                                                                onImport={onImport}/>, { fullScreen: true });
  }

  editAsText() {
    let onImport = (fuses, img, options = {}) => {
      let extendedFuses = this.importFusesAccordingToOptions(fuses, options);
      this.props.onChange(extendedFuses);
      this.modalActionsBus.emit('close');
    };

    const templateById = _.keyBy(this.props.templateFuses, 'id');
    const fuseText = this.props.fuses.map(fuse => [
      fuse.id,
      fuse.kind.type || templateById[fuse.id]?.kind?.type,
      fuse.kind?.amp ? fuse.kind.amp+' A': '',
      fuse.description
    ].join('|')).join('\n')

    this.modalActionsBus.emit('open', <FuseboxTableImgProcessor initialText={fuseText}
                                                                forceParsingColumns={['idNumber', 'type', 'amp', 'description']}
                                                                fuses={this.props.fuses}
                                                                contextId={this.props.contextId}
                                                                onImport={onImport}/>, { fullScreen: true });
  }

  editFootnotes() {
    let footnotes = _.compact(_.uniq(_.flatMap(this.props.fuses, f => (f.description || '').match(/\[[^\]\[]+\]/g))));
    footnotes = footnotes.map(f => f.slice(1, -1));
    this.context.page.openModal(<FootnoteEditor footnotes={footnotes} onSave={(renameMap) => {
      _.each(renameMap, (newValue, oldValue) => {
        if(newValue !== oldValue) {
          for (const f of this.props.fuses) {
            if (f.description) {
              // No new value interpreted as remove footnote
              f.description = f.description.replaceAll('['+oldValue+']', newValue ? '['+newValue.trim()+']' : '');
            }
          }
        }
      });
      this.context.page.closeModal();
      this.props.onChange(this.props.fuses);
    }}/>, { fullScreen: false, title: 'Edit footnotes' });
  }

  deleteTable() {
    if (confirm('Are you sure you want to delete everything?')) {
      this.props.onChange([]);
    }
  }

  extendedFuses() {
    return extendFusesWithTemplate(this.props.fuses, this.props.templateFuses || [], true, this.props.templateRenameMap);
  }

  handleClipboardChange(clipboard) {
    if(_.isString(clipboard) && clipboard) {
      try {
        let json = JSON.parse(clipboard);
        if (_.isArray(json) && json.length && json[0].kind) {
          this.setState({ clipboardRows: json });
          return;
        }
      } catch (err) {

      }
    }
    this.setState({ clipboardRows: null });
  }

  render() {
    let { fuses, templateFuses, selectedFuses } = this.props;
    let { clipboardRows, textFilter } = this.state;

    // If there is a template, merge fuse data with template data and add template fuses not overriden by table
    let extended = this.extendedFuses();

    // Also transform it to pairs before filtering
    let fusePairs = extended.map((f, i) => [f,i]);

    if(textFilter) {
      fusePairs = _.filter(fusePairs, f => JSON.stringify(f[0]).toLowerCase().includes(textFilter.toLowerCase()));
    }

    let selection = _.map(selectedFuses, i => extended[i]);

    const copySelection = async () => selection.length && copyText(JSON.stringify(selection));

    const paste = async () => {
        let rows = clipboardRows.map(r => _.omit(r, 'mergedFromFuse', 'mergedFromTemplate', 'fromTemplate', 'layout'));
        this.props.onChange(this.props.fuses.concat(rows));
    };

    const pasteAmps = async () => {
        let rows = clipboardRows;
        let byId = _.keyBy(rows, 'id');
        for(const f of this.props.fuses) {
          if(f.id) {
            let pastedFuse = byId[f.id];
            if(pastedFuse && pastedFuse.kind?.amp) {
              f.kind = f.kind || {};
              f.kind.amp = pastedFuse.kind.amp;
            }
          }
        }
        this.props.onChange(this.props.fuses);
    };

    const selectAll = () => {
      let newSelection = [];
      if (selection.length < fusePairs.length) {
        newSelection = _.map(fusePairs, '1');
      }
      this.props.onFuseSelection(newSelection);
    };

    const selectNonEmpty = () => {
      let newSelection = [];
      _.each(fusePairs, ([f,i]) => {
        if(!f.kind?.amp && f.kind?.notUsed === undefined) {
          newSelection.push(i);
        }
      });
      this.props.onFuseSelection(newSelection);
    };

    const sortFuses = () => {
      this.props.onChange(_.sortBy(this.props.fuses, f => {
        // Ensure any digits are compared as expected (00001, ... , 000010 vs 1, 10, 2, )
        return f.id.toString().replaceAll(/\d+/g, digits => digits.padStart(5, '0'));
      }));
    };

    const btnSelectionDelete = selection.length && <IconButton icon={'delete_forever'}
                                           onClick={() => selection.length && this.removeFuses(selection)}
                                           level={selection.length ? 'danger' : 'secondary'}
    >
      Delete selected ({selection.length})
    </IconButton>;

    const btnPaste = clipboardRows?.length &&
      <IconButton icon={'content_paste'} onClick={paste} className={'mr-2'}>
        Paste {clipboardRows.length} fuses
      </IconButton>;

    const btnPasteAmps = clipboardRows?.length &&
      <IconButton icon={'pin'} onClick={pasteAmps} className={'mr-2'}>
        Paste Amps
      </IconButton>;

    const btnSelectionCopy = navigator.clipboard && selection.length &&
      <IconButton icon={'content_copy'} onClick={copySelection} className={'mr-2'}>
        Copy ({selection.length})
      </IconButton>;

    const btnSortFuses =  <IconButton icon={'sort_by_alpha'} onClick={sortFuses} className={'mr-2'}>
      Sort
    </IconButton>;

    const selectAllNoneBtn = <IconButton
      icon={selection.length < fusePairs.length ? (selection.length ? 'indeterminate_check_box' : 'check_box_outline_blank') : 'check_box'}
      onClick={selectAll}/>;

    const renameMap = this.props.templateRenameMap;

    return <div className={'px-2'} key={templateFuses ? 'templated' : ''}>
      <ClipboardListener onChange={val => this.handleClipboardChange(val)}/>
      <table className={'small fuse-table-editor bg-light'}>
        <thead>
        <tr>
          <th colSpan={6+(renameMap ? 1 : 0)} className={'text-center zoom-90'}>
            {btnPaste || null}

            {btnSelectionCopy || null}

            {btnSelectionDelete || null}

            {btnSortFuses || null}

            {btnPasteAmps || null}
          </th>

          <th colSpan={2}>
            <SingleTextEditor placeholder={'Filter table...'} small className={'zoom-90'} value={textFilter} onChange={txt => this.setState({ textFilter: txt })}/>
          </th>
        </tr>
        {
          textFilter ? <tr className={'bg-dark text-white'}>
            <td colSpan={8+(renameMap ? 1 : 0)} className={'text-center p-1'}>
              Showing {fusePairs.length} fuses of {extended.length}
            </td>
          </tr> : null
        }
        <tr>
          <th>{selectAllNoneBtn}</th>
          <th>Id</th>
          { renameMap ? <th>Template Id</th> : null}
          <th>Type</th>
          <th>Format</th>
          <th>Amp/Var</th>
          <th onClick={selectNonEmpty} className={'font-italic'}>
            Empty
          </th>
          <th>Fuse Description</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {fusePairs.map(([f, i]) => this.getFuseRow(f, i, fusePairs))}
        </tbody>
      </table>

      <div className={'mt-1'}>
        <button className={'btn btn-sm btn-primary'}
                onClick={() => this.importFusesFromTableImage(this.props.tableImages)}>Parse images as tables...
        </button>

        <button className={'btn btn-sm btn-success ml-2'} onClick={() => this.addFuse()}>Add fuse or relay</button>

        <button className={'btn btn-sm btn-link ml-2'} onClick={() => this.importFusesFromTableImage()}>Import from text/image...</button>

        <button className={'btn btn-sm btn-link ml-2'} onClick={() => this.editAsText()}>Edit as text...</button>

        <button className={'btn btn-sm btn-link ml-2'} onClick={() => this.editFootnotes()}>Edit footnotes...</button>
      </div>
      <ModalManager actionsBus={this.modalActionsBus}/>
    </div>;
  }
}

FuseboxTableEditor.contextType = LegoAdminPageContext;

// For production
// export default FuseboxTableEditor;

// For development
function FB(props) {
  return <FuseboxTableEditor {... props}/>
}
export default FB
