import { useState } from 'react';
import SingleTextEditor from '../common/editors/SingleTextEditor';
import { SwitchInput } from '../common/SwitchInput';
import _ from 'lodash';
import MultiValueSelector from '../common/editors/MultiValueSelector';
import SingleValueSelector from '../common/editors/SingleValueSelector';
import ModelContextEditor from '../lego/ModelContextEditor';

export default function ScriptParameterModal({ script, onCancel, onSubmit }) {
  const {parametersDefinition} = script;

  let defaultParams = {};
  _.each(parametersDefinition, p => defaultParams[p.id] = p.defaultValue);

  let [params, setParams] = useState({});

  let paramsControls = parametersDefinition?.map(({ id, type, description, options, optional, defaultValue }) => {
    let descriptionElem = <span dangerouslySetInnerHTML={{__html: description}}/>;
    let value = params[id];
    if (type === 'string') {
      return <div key={id} className={'p-2'}>
        {descriptionElem}
        <SingleTextEditor placeholder={id} value={value} onChange={v => setParams(p => ({...p, [id]: v}))}/>
      </div>
    } else if (type === 'select') {
      return <div key={id} className={'p-2'}>
        {descriptionElem}
        <SingleValueSelector placeholder={id} value={value || defaultValue} options={options} onChange={v => setParams(p => ({...p, [id]: v}))}/>
      </div>
    } else if (type === 'legoContext') {
      return <div key={id} className={'p-2'}>
        {descriptionElem}
        <ModelContextEditor placeholder={id} value={value} onChange={v => setParams(p => ({...p, [id]: v}))}/>
      </div>
    } else if (type === 'boolean') {
      return <div key={id} className={'p-2'}>
        <SwitchInput value={!!value} onChange={v => setParams(p => ({...p, [id]: v}))}>
          {descriptionElem}
        </SwitchInput>
      </div>
    } else {
      throw new Error(`Unknown parameter type ${type}`);
    }
  });

  return <div>
    {paramsControls}

    <div className={'p-2'}>
      <button className={'btn btn-success mr-2'} onClick={() => onSubmit(params)}>Run script</button>

      <button className={'btn btn-dark'} onClick={onCancel}>Cancel</button>
    </div>

    <pre className={'small bg-light rounded p-1 m-2 text-secondary'}>{JSON.stringify(params, null, 2)}</pre>
  </div>
};
